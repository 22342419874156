<script>
import { getMyAppointments } from "@/services/appointment.service";
import AppointmentList from "@/components/appointment/AppointmentList.vue";
import CreateAppointmentDialog from "@/components/appointment/CreateAppointmentDialog.vue";

export default {
  name: "MyAppointmentsView",
  components: { CreateAppointmentDialog, AppointmentList },
  emits: ["termin-created"],
  data() {
    return {
      appointments: null,
    };
  },
  async created() {
    this.appointments = await getMyAppointments();
  },
  methods: {
    async addAppointment(appointment) {
      this.appointments.push(appointment);
      this.$emit("termin-created");
    },
  },
};
</script>

<template>
  <div>
    <AppointmentList v-if="appointments" :appointments="appointments" />
    <CreateAppointmentDialog @termin-created="addAppointment">
      <template #activator="props">
        <v-btn block color="accent" class="my-5" v-bind="props"
          >Neuer Termin</v-btn
        >
      </template>
    </CreateAppointmentDialog>
  </div>
</template>

<style scoped></style>
