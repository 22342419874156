import ApiService from "@/services/api.service";
import {defineStore} from "pinia";
import {getMyPropertiesByApplicationState} from "@/services/property.service";
import {generateLngLatUrl} from "@/services/distance.service";

const state = () => ({
    savedProperties:{
        loading:false,
      content:[],
      currentPage:null
    },
    ownedProperties:{
        loading:false,
        content:[],
        currentPage:null
    },
    archivedProperties:{
        loading:false,
        content:[],
        currentPage:null
    },
    soldProperties:{
      loading:false,
        content:[],
        currentPage:null
    },
   discoverProperties:{
       content:[],
       loading:false,
       currentPage:{
           "pageable": {
               "pageNumber": 0,
               "pageSize": -1,
               "sort": {
                   "empty": true,
                   "sorted": false,
                   "unsorted": true
               },
               "offset": 0,
               "paged": true,
               "unpaged": false
           },
           "totalPages": -1,
           "totalElements": -1,
           "last": true,
           "size": -1,
           "number": 0,
           "sort": {
               "empty": true,
               "sorted": false,
               "unsorted": true
           },
           "numberOfElements": -1,
           "first": true,
           "empty": false
       }
   },
});

export const usePropertyStore = defineStore('propertyStore', {
    state: state,

    getters: {
        getSoldProperties() {
            if (!this.soldProperties.currentPage || this.soldProperties.currentPage.totalPages === -1){
                this.fetchSoldProperties(0);
            }
            return this.soldProperties;
        },
        getArchivedProperties() {
          if(!this.archivedProperties.currentPage || this.archivedProperties.currentPage.totalPages === -1){
              this.fetchArchivedProperties(0);
          }
        }
    },

    actions: {
        async addPropertyRelation(propertyId,relationType){
            const response = await ApiService.get("user/property/relation/add/"+propertyId+"/"+relationType);
            let property = this.discoverProperties.content.find(p=>p.id===propertyId);
            if(property){
                console.log("rESPONMSE",response);
                property.myRoles.push(response.data);
                this.savedProperties.content.push(property);
            }
            return response.data;
        },
        async removePropertyRelation(propertyId,relationType){
            const response = await ApiService.get("user/property/relation/remove/"+propertyId+"/"+relationType);
            let property = this.discoverProperties.content.find(p=>p.id===propertyId);
            if(property){
                property.myRoles = property.myRoles.filter(role=>role!==relationType);
                this.savedProperties.content = this.savedProperties.content.filter(p=>p.id!==propertyId);
            }
            return response.data;
        },
        async fetchSoldProperties(page){
          this.soldProperties.loading=true;
            try{
                const relations = ["OWNER","SAVED","MAKLER"];
                const response = await getMyPropertiesByApplicationState("SOLD",relations,page);
                let properties = response.data.content;
                let currentPage = response.data;
                this.soldProperties.content.push(...properties);
                this.soldProperties.currentPage=currentPage;
                this.soldProperties.loading=false;
            }catch (e){
                this.soldProperties.loading=false;
            }finally {
                this.soldProperties.loading=false;
            }
        },
        async fetchArchivedProperties(page){
          this.archivedProperties.loading=true;
            try{
                const relations = ["OWNER","SAVED","MAKLER"];
                const response = await getMyPropertiesByApplicationState("ARCHIVED",relations,page);
                let properties = response.data.content;
                let currentPage = response.data;
                this.archivedProperties.content.push(...properties);
                this.archivedProperties.currentPage=currentPage;
                this.archivedProperties.loading=false;
            }catch (e){
                this.archivedProperties.loading=false;
            }finally {
                this.archivedProperties.loading=false;
            }
        },
        async fetchOwnedProperties(page,lngLat){
          this.ownedProperties.loading=true;
          console.log("Fetch owned properties",page,lngLat);
            try{
                const response = await ApiService.get("property/my/OWNER/page/"+page+generateLngLatUrl(lngLat));
                let properties = response.data.content;
                let currentPage = response.data;
                this.ownedProperties.content= properties;
                this.ownedProperties.currentPage=currentPage;
                this.ownedProperties.loading=false;
            }catch (e){
                this.ownedProperties.loading=false;
            }finally {
                this.ownedProperties.loading=false;
            }
        },
        async fetchSavedProperties(page,lngLat){
            this.savedProperties.loading=true;
        try{
            const response = await ApiService.get("property/my/SAVED/page/"+page+generateLngLatUrl(lngLat));
            let properties = response.data.content;
            console.log("Properties",properties);
            let currentPage = response.data;
            this.savedProperties.content = properties;
            this.savedProperties.currentPage=currentPage;
            this.savedProperties.loading=false;
        }catch (e){
            this.savedProperties.loading=false;
        }finally {
            this.savedProperties.loading=false;
        }
        },
        async fetchDiscoverProperties(page,lngLat) {
            this.discoverProperties.loading=true;
            try{
                const response = await ApiService.get("property/all/page/"+page+generateLngLatUrl(lngLat));
                let properties = response.data.content;
                let currentPage = response.data;
                this.discoverProperties.content=properties;
                this.discoverProperties.currentPage=currentPage;
            }catch (e){
                console.log(e);
                this.discoverProperties.loading=false;
            }finally {
                this.discoverProperties.loading=false;
            }

        },
        async fetchDiscoverPropertiesNextPage() {
            await this.fetchDiscoverProperties(this.discoverProperties.currentPage.pageable.pageNumber+1);
        }

    },
});