<template>
  <div>
    <v-row>
      <v-col class="text-left" cols="12" sm="6">
        <h1 style="font-size: 2rem">Grundstück</h1>

        <div class="mt-5 mb-3">
          <h2>Adresse</h2>
          <div class="mb-5" v-text="land.address"></div>
        </div>
      </v-col>
      <!-- <v-col cols="12" sm="6" class="mt-5">
      <div class="pa-5" v-html="svg"></div>
    </v-col> -->
    </v-row>
    <v-form @submit.prevent="create" ref="form" class="d-flex flex-wrap">
      <v-row
        ><v-col cols="12">
          <v-text-field label="Titel" v-model="land.label"></v-text-field>
        </v-col>
        <v-col cols="12">
          <KreaNumberInput label="Grundstücksfläche" v-model="land.plotArea"
            ><template #append-inner
              ><span class="text-grey">m²</span></template
            ></KreaNumberInput
          >
        </v-col>
        <v-col cols="12" class="py-0">
          <v-btn block size="large" type="submit" color="accent"
            >Objekt anlegen</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            @click="$emit('close')"
            color="grey-darken-3"
            text="Abbrechen"
          ></v-btn> </v-col
      ></v-row>
    </v-form>
    <v-dialog v-model="showDialog" persistent max-width="400px">
      <template v-slot:activator="{ props: activatorProps }">
        <slot name="activator" :activator="activatorProps"> </slot>
      </template>
      <v-card class="pa-3">
        <v-card-title class="text-center">
          <v-icon size="small" class="mr-1">mdi-check</v-icon>
          Grundstück erfolgreich angelegt
        </v-card-title>
        <v-card-text>
          <p>
            Das Grundstück wurde erfolgreich angelegt. Möchten Sie weitere
            Details hinzufügen oder zurück zur Übersicht?
          </p>
        </v-card-text>
        <v-card-actions class="d-flex flex-column">
          <v-btn
            class="ma-0"
            size="large"
            variant="flat"
            block
            append-icon="mdi-arrow-right"
            @click="$router.push(`/property/${createdLandId}/edit/basic`)"
            color="accent"
            >Details hinzufügen</v-btn
          >
          <v-btn
            block
            class="bg-grey-darken-3 ma-0 mt-2"
            @click="
              showDialog = false;
              $emit('success');
            "
            >Zurück zur Übersicht</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CreateLandRequest } from "@/models/create/CreateLandRequest";
import KreaNumberInput from "@/components/KreaNumberInput";
import { useNotificationStore } from "@/stores/modules/notification.module";
import { getAddressFromLatLng } from "@/services/map.service";
import { createLand } from "@/services/property.service";

export default {
  name: "CreateLand",
  components: { KreaNumberInput },
  emits: ["success", "close"],
  setup() {
    // const mapStore = useMapStore();
    const notificationStore = useNotificationStore();

    return {
      // mapStore,
      notificationStore,
    };
  },
  props: {
    property: null,
    clickEvent: null,
    parentId: Number,
  },
  created() {
    // if (this.land) this.request = this.land;
    this.select(this.property, this.clickEvent);
  },
  watch: {
    property(newVal) {
      this.select(newVal, this.clickEvent);
    },
    // request: {
    //   handler(newVal) {
    //     this.$emit("update:request", newVal);
    //   },
    //   deep: true,
    // },
  },
  methods: {
    async select(property, clickEvent) {
      let address = await getAddressFromLatLng(clickEvent.lngLat);
      // let mapMeta = null;
      // if (property.geometry.type === "Polygon") {
      //   mapMeta = new MapMeta({
      //     height: this.property.properties.height,
      //     minHeight: this.property.properties.min_height,
      //     buildingId: this.property.id,
      //   });
      // }
      this.land = new CreateLandRequest({
        location: property.geometry,
        buildingId: property.id,
        address: address,
        // mapMeta: mapMeta,
      });
      // if (property.geometry.type === "Polygon") {
      //   this.svg = drawService.generateSVGFromCoordinates(
      //     property.geometry.coordinates,
      //     200
      //   );
      // }
    },
    async validateForm() {
      if (!this.$refs.form) return false;
      let response = await this.$refs.form.validate();
      return response.valid;
    },
    async create() {
      if (await this.validateForm()) {
        let land = JSON.parse(JSON.stringify(this.land));
        try {
          let data = await createLand(land);
          // try {
          //   let mapJson = JSON.parse(data.mapJson);
          //   this.mapStore.updateData(mapJson);
          // } catch (error) {
          //   console.log("Keine Map gefunden");
          // }
          this.createdLandId = data.id;
          this.showDialog = true;
        } catch (error) {
          this.notificationStore.pushError("Ein Fehler ist aufgetreten", error);
        }
      }
    },
  },
  data() {
    return {
      land: new CreateLandRequest({}),
      showDialog: false,
      createdLandId: null,
    };
  },
};
</script>

<style scoped></style>
