<template>
  <v-card class="mb-5 mx-1 pa-3 black lighten-2" flat rounded>
    <div class="d-flex justify-space-between align-center mb-4">
      <h2 class="text-h6 font-weight-bold">Mein Admin Portfolio</h2>
    </div>
    <v-row>
      <v-col class="pa-1" cols="12" sm="3">
        <v-card
          flat
          class="pa-3 h-100 d-flex flex-column align-center justify-center bg-grey-darken-35"
        >
          <p class="text-disabled mb-0 flex-wrap">Gebote</p>
          <p class="text-h5 font-weight-bold mb-0">
            <span>{{ data.countOffers }}</span>
          </p>
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="12" sm="9">
        <v-card
          flat
          class="d-flex align-center justify-center pa-2 bg-grey-darken-35 h-100"
        >
          <BarChart
            v-if="chartData.length > 0"
            label-name="EUR"
            show-y
            show-x
            anchor-label="center"
            align-label="end"
            index-axis="y"
            :data="chartData"
          ></BarChart>
          <span
            class="font-weight-medium text-disabled d-flex align-center justify-center"
            v-else
            >Nicht genügend Daten vorhanden</span
          >
        </v-card>
      </v-col>
      <v-col cols="12" class="pa-1"
        ><v-card flat class="bg-grey-darken-35">
          <p class="text-body-1 text-left text-disabled ma-2">
            Maklerübersicht
          </p>
          <!-- <p
        v-else
        class="text-body-1 text-center font-weight-medium text-disabled ma-2"
      >
        Keine Angebote vorhanden
      </p> -->
          <div v-if="agentList.length > 0" class="overflow-y-hidden pa-1">
            <v-row class="flex-nowrap" style="width: max-content">
              <v-col
                v-for="(agent, index) in agentList"
                :key="index"
                cols="auto"
                class="pa-0 pl-2 pr-2 py-2 ml-1 my-2"
                v-tooltip:top="{
                  text: 'Klicken, um mehr Informationen anzuzeigen',
                  openDelay: 2000,
                }"
              >
                <SimpleUserListItem :user="agent"></SimpleUserListItem>
              </v-col>
            </v-row>
          </div>
          <p
            v-else
            class="text-body-1 text-center font-weight-medium text-disabled ma-2"
          >
            Noch keine Makler vorhanden
          </p></v-card
        ></v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import BarChart from "@/components/data/BarChart.vue";
import SimpleUserListItem from "@/components/user/SimpleUserListItem.vue";
import { useNotificationStore } from "@/stores/modules/notification.module";
import ApiService from "@/services/api.service";

export default {
  name: "AdminInsights",
  components: {
    BarChart,
    SimpleUserListItem,
  },
  setup() {
    const notificationStore = useNotificationStore();
    return {
      notificationStore,
    };
  },
  data() {
    return {
      chartData: [],
      data: {},
      agentList: [],
    };
  },
  methods: {
    async getAdminInsights() {
      try {
        const response = await ApiService.get("dashboard/admin");
        this.data = response.data;

        if (
          this.data.sumPropertiesValue !== undefined &&
          this.data.sumPropertiesValue !== null
        ) {
          this.chartData.push({
            label: "Gesamtwert",
            value: this.data.sumPropertiesValue,
          });
        }

        if (
          this.data.sumPropertiesValueWithSignedMandate !== undefined &&
          this.data.sumPropertiesValueWithSignedMandate !== null
        ) {
          this.chartData.push({
            label: "Wert unter Mandat",
            value: this.data.sumPropertiesValueWithSignedMandate,
          });
        }

        if (
          this.data.potentialProvisionValue !== undefined &&
          this.data.potentialProvisionValue !== null
        ) {
          this.chartData.push({
            label: "Pot. Provisionserlös",
            value: this.data.potentialProvisionValue,
          });
        }
      } catch (error) {
        this.notificationStore.pushError("Fehler beim Laden der Daten.");
      }
    },
    async getAgentList() {
      try {
        const response = await ApiService.get("dashboard/admin/maklers");
        this.agentList = response.data;
        console.log("Agent List", this.agentList);
        console.log(this.agentList.length > 0);
      } catch (error) {
        this.notificationStore.pushError("Fehler beim Laden der Daten.");
      }
    },
  },
  async created() {
    await this.getAdminInsights();
    await this.getAgentList();
  },
};
</script>

<style scoped>
.bg-grey-darken-35 {
  background-color: #282727;
}
.v-col {
  padding: 0;
}
</style>
