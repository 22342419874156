<template>
  <v-text-field
    :append-inner-icon="appendInnerIcon"
    :prepend-icon="prependIcon"
    v-model="formattedValue"
    :rules="rules"
    :label="label"
    :persistent-hint="persistentHint"
  >
    <template #append-inner>
      <slot name="append-inner"> </slot>
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: "KreaNumberInput",
  props: {
    persistentHint: {
      type: Boolean,
      default: false,
    },
    modelValue: String,
    label: String,
    prependIcon: String,
    appendInnerIcon: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "validationResult"],
  data: () => ({
    value: "",
  }),
  computed: {
    rules() {
      let rules = [
        (value) =>
          (!value && !this.required) ||
          /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/.test(
            (value || "").replace(/[.,]/g, "")
          ) ||
          "Only digits and commas are allowed",
        // (value) => !/^0/.test(value || "") || "Cannot start with 0",
      ];
      if (this.required) {
        rules.unshift(
          (value) => !!value || "Value is required",
          (value) =>
            (value ? value.replace(/[.,]/g, "") > 0 : false) ||
            "Must be greater 0"
        );
      }
      return rules;
    },

    formattedValue: {
      get() {
        return this.modelValue
          ?.toString()
          .replace(".", ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      set(value) {
        // Remove dots and commas before updating the model value
        const unformattedValue = value
          .toString()
          .replace(/[.]/g, "")
          .replace(",", ".");
        this.$emit("update:modelValue", unformattedValue);
      },
    },
  },
  methods: {
    validateAndEmit(value) {
      const rules = this.rules();
      let isValid = true;
      for (let i = 0; i < rules.length; i++) {
        const result = rules[i](value);
        if (result !== true) {
          isValid = false;
          break;
        }
      }
      this.$emit("validationResult", isValid);
      if (isValid) {
        this.$emit("update:modelValue", value);
      }
    },
    handleInput(event) {
      // Filter out non-digit and non-comma characters
      const newValue = event.target.value.replace(/[^\d,]/g, "");
      // Update the input value with filtered value
      event.target.value = newValue;
      // Emit the filtered value to update the model
      this.$emit("update:modelValue", newValue);
    },
  },
};
</script>
