<template>
  <v-container
    style="height: 100%; max-width: none"
    :class="{ 'pl-5': $vuetify.display.smAndDown }"
  >
    <v-row class="d-flex flex-wrap" style="height: 100%">
      <v-col :class="$vuetify.display.mdAndUp ? 'w-60' : 'w-100'">
        <div>
          <div class="d-flex" style="gap: 20px">
            <SearchComponent
              @update:adress="searchedAdress = $event"
              v-model:adress="searchedAdress"
              v-model="searchedLocation"
              density="default"
            />
            <v-btn
              v-if="$vuetify.display.mdAndUp"
              :append-icon="mapStore.show ? 'mdi-close' : 'mdi-arrow-left'"
              v-tooltip:top="{
                text: mapStore.show ? 'Karte ausblenden' : 'Karte einblenden',
                openDelay: 1000,
              }"
              height="55"
              @click="toggleMap"
              >Karte</v-btn
            >
          </div>

          <h2 class="text-left mb-2">Immobilien</h2>
          <div v-if="currentTab !== 0">
            <p
              class="text-left caption text-grey mb-2"
              v-if="!searchedLocation"
            >
              Entfernung zu deinem aktuellen Standort
            </p>
            <p class="text-left caption text-grey mb-1" v-else>
              Entfernung zu {{ searchedAdress }}
            </p>
          </div>
          <div ref="content" class="animated">
            <PropertyListUser
              :properties="properties"
              @update:tab="handleTabChange"
            />
          </div>
        </div>
      </v-col>
      <v-col
        v-if="$vuetify.display.mdAndUp"
        v-show="mapStore.show"
        ref="mapCol"
        class="animated w-40"
      >
        <MapComponent :hide-search="true" :popup="true" v-model="location" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MapComponent from "@/components/MapComponent";
import PropertyListUser from "@/components/property/PropertyListUser.vue";
import { useMapStore } from "@/stores/modules/map.module";
import SearchComponent from "@/components/map/SearchComponent.vue";
import { usePropertyStore } from "@/stores/modules/property.module";
export default {
  name: "PropertyView",
  components: { SearchComponent, PropertyListUser, MapComponent },
  setup() {
    const mapStore = useMapStore();
    const propertyStore = usePropertyStore();
    return {
      mapStore,
      propertyStore,
    };
  },
  created() {
   // this.getProperties();
  },
  methods: {
    toggleMap() {
      this.mapStore.toggleMap();
      setTimeout(() => {
        this.mapStore.map.resize();
      }, 50);
    },
    async getProperties() {
      //this.properties = await getProperties();
    },
    handleTabChange(tab) {
      this.currentTab = tab;
    },
  },
  watch: {
    searchedLocation: {
      handler() {
        console.log("Searched Location", this.searchedLocation);
        //TODO fetch properties but dont push to store to existing items, overwrite existing
        this.propertyStore.fetchDiscoverProperties(0, this.searchedLocation);
      },
    },
  },
  data() {
    return {
      currentTab: 0,
      searchedLocation: null,
      searchedAdress: null,
      properties: [],
      location: {
        lat: 48.151128223000306,
        lng: 11.556244519262124,
        bearing: 0,
        pitch: 0,
        zoom: 10,
      },
    };
  },
};
</script>

<style scoped>
.map-smaller {
  height: 200px !important;
}
.animated {
  transition: width, height 0.5s ease-in-out;
}
.content-larger {
  width: 80vw !important;
  max-width: 1100px !important;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
.v-container {
  padding-top: 0;
  padding-left: 0;
}
</style>
