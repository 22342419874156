<template>
  <div
    class="chart-container"
    style="position: relative; height: 100%; width: 100%"
  >
    <canvas style="width: 100%; height: 100%" ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    indexAxis: {
      type: String,
      default: "x",
    },
    showX: {
      type: Boolean,
      default: false,
    },
    showY: {
      type: Boolean,
      default: false,
    },
    labelName: {
      type: String,
      default: "Data",
    },
    anchorLabel: {
      type: String,
      default: "end",
    },
    alignLabel: {
      type: String,
      default: "start",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.renderChart();
  },
  watch: {
    data() {
      if (this.chart) {
        this.chart.destroy();
        this.renderChart();
      }
    },
    showX() {
      this.toggleAxis();
    },
    showY() {
      this.toggleAxis();
    },
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.chartCanvas.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.data.map((item) => item.label),
          datasets: [
            {
              label: this.labelName,
              data: this.data.map((item) => item.value),
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderRadius: 10,
              borderWidth: 1,
              barPercentage: 0.8,
              categoryPercentage: 0.9,
            },
            {
              type: "line", // Setzen Sie den Typ auf 'line', um eine Linie zu zeichnen
              label: "Durchschnitt",
              data: this.data.map((item) => item.average), // Verwenden Sie den Durchschnittswert aus Ihren Daten
              borderColor: "rgba(75, 192, 192, 1)", // Setzen Sie die Farbe der Linie
              borderWidth: 1,
              fill: false,
              showLine: true,
            },
          ],
        },
        options: {
          indexAxis: this.indexAxis,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              color: "white",
              anchor: this.anchorLabel,
              align: this.alignLabel,

              offset: 4,
              font: {
                weight: "bold",
                size: 16,
              },
              formatter: (value, context) => {
                // Hilfsfunktion zum Formatieren der Zahlen
                function formatNumber(num) {
                  if (num >= 1000000) {
                    return parseFloat((num / 1000000).toFixed(2)) + " Mio.";
                  } else if (num >= 1000) {
                    return parseFloat((num / 1000).toFixed(2)) + " Tsd.";
                  } else if (num >= 1000000000) {
                    return parseFloat((num / 1000000000).toFixed(2)) + " Mrd.";
                  } else {
                    return num;
                  }
                }

                // Überprüfen Sie, ob das aktuelle Dataset das Durchschnitts-Dataset ist
                if (context.dataset.label === "Durchschnitt") {
                  // Wenn ja, fügen Sie das Durchschnittssymbol vor dem Wert ein
                  return `⌀ ${formatNumber(value).toLocaleString()} €`;
                } else {
                  // Wenn nicht, geben Sie den Wert wie gewohnt zurück
                  return `${formatNumber(value).toLocaleString()} €`;
                }
              },
            },
          },
          scales: {
            x: {
              display: this.showX,

              // ticks: {
              //   callback: function (value) {
              //     return value.toLocaleString() + " €";
              //   },
              // },
            },
            y: {
              display: this.showY,
              beginAtZero: true,
              ticks: {
                // Setzen Sie dies auf 90, um die Beschriftung vertikal zu machen
                mirror: false, // Setzen Sie dies auf false, um die Beschriftung nicht zu spiegeln
                padding: 10,
                align: "center",
                font: {
                  size: 12,
                },
              },
            },
          },
          layout: {},
        },
      });
    },
    toggleAxis() {
      if (this.chart) {
        this.chart.options.scales.x.display = this.showX;
        this.chart.options.scales.y.display = this.showY;
        this.chart.update();
      }
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
