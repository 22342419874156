<template>
  <div class="pa-5">
    <CompleteSaleProcess :propertyId="propertyId" />
    <div v-show="loading">
      <v-progress-linear indeterminate color="accent"></v-progress-linear>
    </div>
    <v-data-iterator
      style="overflow-x: scroll; overflow-y: hidden"
      items-per-page="10"
      v-show="!loading"
      :loading="loading"
      :items="columns"
      content-tag="v-layout"
      row
    >
      <template v-slot:default="props">
        <div class="d-flex ga-5" style="height: 600px">
          <div
            v-for="(column, $index) of props.items"
            style="height: 100%"
            :key="$index"
          >
            <v-card
              class="bg-grey-darken-4"
              style="height: 100%; min-width: 280px"
            >
              <v-card-title
                class="text-left font-weight-bold text-grey d-flex justify-space-between"
                style="font-size: 1.1rem"
              >
                <span>{{ column.raw.label }}</span>
                <span>{{ column.raw.items.length }}</span>
              </v-card-title>

              <draggable
                class="h-100"
                v-model="column.raw.items"
                item-key="id"
                group="processes"
                :data-status="column.raw.name"
                @end="dragEnd"
              >
                <template #item="{ element }">
                  <PropertyItemState
                    :key="element.id"
                    :property-state="element"
                  />
                </template>
              </draggable>
            </v-card>
          </div>
        </div>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  changeStatus,
  loadPropertySaleStates,
} from "@/services/saleProcess/process.service";
import PropertyItemState from "@/components/property/PropertyItemState.vue";
import CompleteSaleProcess from "@/components/agent/property/CompleteSaleProcess.vue";

export default {
  components: {
    CompleteSaleProcess,
    PropertyItemState,
    draggable,
  },
  props: {
    propertyId: String,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          name: "PENDING",
          label: "Nicht festgelegt",
          englishLabel: "Offer pending",
          items: [],
        },
        {
          name: "INDIKATIVES_ANGEBOT",
          label: "Indikatives Angebot",
          englishLabel: "Indicative offer",
          items: [],
        },
        {
          name: "EXCLUSIVE_OFFER",
          label: "Exklusives Angebot",
          englishLabel: "Exclusive offer",
          items: [],
        },
        {
          name: "DUE_DILIGENCE",
          label: "Due Diligence",
          englishLabel: "Due Diligence",
          items: [],
        },
        {
          name: "PREISVERHANDLUNG",
          label: "Preisverhandlung",
          englishLabel: "Price negotiation",
          items: [],
        },
        {
          name: "NOTARTERMIN",
          label: "Notartermin",
          englishLabel: "Notary appointment",
          items: [],
        },
        {
          name: "UEBERGABE",
          label: "Übergabe",
          englishLabel: "Handover",
          items: [],
        },
        {
          name: "VERKAUFT",
          label: "Verkauft",
          englishLabel: "Sold",
          items: [],
        },
        {
          name: "ARCHIVED",
          label: "Archiviert",
          englishLabel: "Archived",
          items: [],
        },
      ],
      processes: [
        // your processes data here
      ],
    };
  },
  async created() {
    this.loading = true;
    this.processes = await loadPropertySaleStates(this.propertyId);
    this.processes.forEach((process) => {
      const column = this.columns.find(
        (column) => column.name === process.status
      );
      if (column) column.items.push(process);
    });
    this.loading = false;
  },
  methods: {
    async changeStatus(processId, status) {
      return await changeStatus(processId, status);
    },
    async dragEnd(event) {
      console.log("Event", event);
      console.log("Status", event.to.dataset.status);
      let processId = event.item.__draggable_context.element.id;

      switch (event.to.dataset.status) {
        case "DUE_DILIGENCE":
          if (
            confirm(
              "Sind Sie sicher, dass Sie in die Due Dilligence wechseln wollen? \n Dadurch wird dem Mandanten der komplette GIF Raum freigegeben."
            ) === false
          ) {
            // move item back to original column
            let oldColumn = this.columns.find(
              (column) => column.name === event.from.dataset.status
            );
            oldColumn.items.push(event.item.__draggable_context.element);

            let newColumn = this.columns.find(
              (column) => column.name === event.to.dataset.status
            );
            newColumn.items.splice(
              newColumn.items.findIndex((x) => x.id === processId),
              1
            );
            return;
          }
          break;
      }

      try {
        await this.changeStatus(processId, event.to.dataset.status);
      } catch (e) {
        // move item back to original column
        let oldColumn = this.columns.find(
          (column) => column.name === event.from.dataset.status
        );
        oldColumn.items.push(event.item.__draggable_context.element);

        let newColumn = this.columns.find(
          (column) => column.name === event.to.dataset.status
        );
        newColumn.items.splice(
          newColumn.items.findIndex((x) => x.id === processId),
          1
        );
      }
    },
  },
};
</script>

<style scoped>
.bg-grey-darken-5 {
  background-color: #93b19521;
}
</style>
