<template>
  <div style="width: 100%; height: 100%; max-height: 85vh; position: relative">
    <div ref="mapContainer" class="rounded map-container"></div>
    <div
      class="pa-5"
      style="
        width: 400px;
        max-width: 90%;
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 99;
      "
    >
      <SearchComponent v-if="!hideSearch" />
    </div>
    <div style="display: none">
      <div id="popup" ref="htmlPopup" @click="test">
        <div style="color: black" ref="popupText" id="popup-text"></div>
        <CreateBuidlingPopup :property="property" :click-event="clickEvent">
          <template #activator="{ activator }">
            <v-btn class="mt-3" v-bind="activator">Hinzufügen</v-btn>
          </template>
        </CreateBuidlingPopup>
      </div>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import {
  addControls,
  addPropertyLayer,
  getAddressFromLatLng,
  loadData,
  setHomeMarker,
  setUserLocation,
} from "@/services/map.service";

import _ from "lodash"; // Import lodash for debouncing
import { useMapStore } from "@/stores/modules/map.module";
import { useNavStore } from "@/stores/modules/nav.module";
import CreateBuidlingPopup from "@/components/property/create/CreateBuidlingPopup.vue";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import SearchComponent from "@/components/map/SearchComponent";
mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;
export default {
  name: "MapComponent",
  components: { SearchComponent, CreateBuidlingPopup },
  props: ["modelValue", "shrink", "popup", "simple", "noGeolocate","hideSearch"],
  emits: ["propertyClick", "update:modelValue", "buildingClick"],
  setup() {
    const authStore = useAuthUserStore();
    const mapStore = useMapStore();
    const navStore = useNavStore();
    return {
      mapStore,
      navStore,
      authStore,
    };
  },
  data() {
    return {
      userHome: null,
      property: null,
      clickEvent: null,
      location: {
        lat: 48.151128223000306,
        lng: 11.556244519262124,
        bearing: 0,
        pitch: 0,
        zoom: 0,
      },
    };
  },
  watch: {
    shrink(newVal) {
      if (newVal) this.$refs.mapContainer.style.width = "calc(100% - 600px)";
      else this.$refs.mapContainer.style.width = "100%";
      this.mapStore.map.resize();
    },
  },
  async mounted() {
    const { lng, lat, zoom, bearing, pitch } = this.modelValue;
    const map = new mapboxgl.Map({
      container: this.$refs.mapContainer,
      style: "mapbox://styles/mapbox/dark-v11", // Replace with your preferred map style
      center: [lng, lat],
      bearing,
      pitch,
      zoom,
    });

    this.mapStore.setMap(map);
    this.mapStore.startRotation();
    //Load data from server for properties
    let data = await loadData();
    map.on("load", async () => {
      if (this.mapStore.geolocate && !this.noGeolocate)
        this.mapStore.geolocate.trigger();
    });
    map.on("styledata", () => {
      // Insert the layer beneath any symbol layer.
      const layers = map.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === "symbol" && layer.layout["text-field"]
      ).id;
      if (!map.getLayer("add-3d-buildings")) {
        map.addLayer(
          {
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["all", ["==", "extrude", "true"]],
            type: "fill-extrusion",
            minzoom: 12,
            paint: {
              "fill-extrusion-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                "#ff0000", // Color when building is hovered
                ["boolean", ["feature-state", "selected"], false], // If feature is selected
                "#253326",
                "#93B195", // Default color
              ],

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.3,
            },
          },
          labelLayerId
        );
      }

      addPropertyLayer(map, data);
      // The 'building' layer in the Mapbox Streets
      // vector tileset contains building height data
      // from OpenStreetMap.
    });

    let self = this;
    const updateLocation = _.debounce(() => {
      // Debounce the updateLocation function
      if (this)
        this.$emit("update:modelValue", (self.location = self.getLocation()));
    }, 200);
    map.on("move", updateLocation);
    map.on("zoom", updateLocation);
    map.on("rotate", updateLocation);
    map.on("pitch", updateLocation);

    this.mapStore.setMap(map);
    setUserLocation(!this.simple);
    addControls(map);

    this.setHomeLocation();
    this.handlePropertyClick();
    this.handleBuildingClick();

    // Change the cursor to a pointer when the mouse is over the places layer.
  },
  unmounted() {
    this.mapStore.removeMap();
    //this.mapStore.map.remove();
  },
  computed: {
    currentUser() {
      return this.authStore.user;
    },
  },
  methods: {
    setHomeLocation() {
      setHomeMarker(this.mapStore.map);
    },
    async select(property, clickEvent) {
      this.property = property;
      console.log("Property", this.property);
      this.clickEvent = clickEvent;
      const drawService = require("@/services/draw.service");
      let address = await getAddressFromLatLng(clickEvent.lngLat);
      let mapMeta = {
        height: property.properties.height,
        minHeight: property.properties.min_height,
        id: property.id,
      };
      let building = {};
      building.location = property.geometry;
      building.buildingId = property.id;
      building.address = address;
      building.meta = mapMeta;
      let svg = {};
      svg.value = drawService.generateSVGFromCoordinates(
        property.geometry.coordinates,
        200
      );
      this.$refs.popupText.innerText = building.address;
    },
    handlePropertyClick() {
      //Click on Property
      this.mapStore.map.on("click", "add-3d-properties", (e) => {
        this.$emit("propertyClick", e);
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates[0];

        this.mapStore.flyToPolygon(coordinates);
      });
      this.mapStore.map.on("mouseenter", "add-3d-properties", () => {
        this.mapStore.map.getCanvas().style.cursor = "pointer";
      });
      this.mapStore.map.on("mouseleave", "add-3d-properties", () => {
        this.mapStore.map.getCanvas().style.cursor = "grab";
      });
    },
    handleBuildingClick() {
      this.mapStore.map.on("click", "add-3d-buildings", (e) => {
        console.log("Handle Building Click");
        if (
          this.mapStore.map
            .queryRenderedFeatures(e.point)
            .some((feature) => feature.state.registered)
        ) {
          return;
        }
        this.$emit("buildingClick", e);
        this.select(e.features[0], e);
        if (e.features)
          this.mapStore.selectBuilding(this.mapStore.map, e.features[0]);

        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        if (this.popup) {
          console.log("Popup");
          new mapboxgl.Popup()
            .setLngLat(coordinates[0][0])
            .setDOMContent(this.$refs.htmlPopup)
            .addTo(this.mapStore.map);
        }
      });
      this.mapStore.map.on("mouseenter", "add-3d-buildings", () => {
        this.mapStore.map.getCanvas().style.cursor = "pointer";
      });
      this.mapStore.map.on("mouseleave", "add-3d-buildings", () => {
        this.mapStore.map.getCanvas().style.cursor = "grab";
      });
    },

    getLocation() {
      if (this.mapStore.map) {
        return {
          ...this.mapStore.map.getCenter(),
          bearing: this.mapStore.map.getBearing(),
          pitch: this.mapStore.map.getPitch(),
          zoom: this.mapStore.map.getZoom(),
        };
      } else {
        return {
          ...this.location,
        };
      }
    },
  },
};
</script>

<style scoped>
.map-container {
  height: 100%;
  flex: 1;
  width: 100%;
}
.v-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
