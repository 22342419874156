<template>
  <div class="d-flex flex-wrap">
    <div
      class="px-5"
      :class="$vuetify.display.mdAndUp ? 'w-60' : 'w-100'"
      style="min-height: 100vh"
    >
      <v-tabs v-model="tab">
        <v-tab>Übersicht</v-tab>
        <v-tab>Termine</v-tab>
        <!-- <v-tab>Kontakte</v-tab> -->
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item>
          <DashboardOverview ref="updateClosestAppointment" />
        </v-window-item>
        <v-window-item>
          <MyAppointmentsView @termin-created="handleAppointmentUpdate" />
        </v-window-item>
      </v-window>
    </div>
    <div v-if="$vuetify.display.mdAndUp" class="w-40" style="min-height: 100vh">
      <MapComponent :popup="true" v-model="location" />
    </div>
  </div>
</template>

<script>
import MapComponent from "@/components/MapComponent";
import DashboardOverview from "@/components/dashboard/DashboardOverview.vue";
import MyAppointmentsView from "@/views/MyAppointmentsView.vue";

export default {
  name: "DashboardView",
  components: { DashboardOverview, MapComponent, MyAppointmentsView },
  data() {
    return {
      tab: 0,
      location: {
        lat: 48.151128223000306,
        lng: 11.556244519262124,
        bearing: 0,
        pitch: 0,
        zoom: 2,
      },
    };
  },
  methods: {
    handleAppointmentUpdate() {
      this.$refs.updateClosestAppointment.getClosestAppointment();
    },
  },
};
</script>

<style scoped>
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
</style>
