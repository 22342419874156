<script>
import {
  acceptOffer,
  counterOffer,
  declineOffer,
  pendingOffer,
} from "@/services/saleProcess/offer.service";

export default {
  name: "OfferActionButtonsOwner",
  methods: {
    async accept() {
      if (
        confirm(
          "Möchten Sie das Angebot wirklich annehmen?\nDer Käufer wird benachrichtigt."
        )
      ) {
        if (this.propertyId && this.userId) {
          let status = await acceptOffer(this.propertyId, this.userId);
          this.$emit("changeStatus", status);
        }
      }
    },
    async deny() {
      if (
        confirm(
          "Möchten Sie das angebot wirklich ablehnen?\nDer Käufer wird benachrichtigt."
        )
      ) {
        if (this.propertyId && this.userId) {
          let status = await declineOffer(this.propertyId, this.userId);
          this.$emit("changeStatus", status);
        }
      }
    },
    async pedingOffer() {
      if (
        confirm(
          "Möchten Sie das Angebot auf wartend setzen?\nDer Käufer wird benachrichtigt."
        )
      ) {
        if (this.propertyId && this.userId) {
          let status = await pendingOffer(this.propertyId, this.userId);
          this.$emit("changeStatus", status);
        }
      }
    },
    async counter() {
      let value = prompt("Geben Sie ein Gegenangebot ein.");
      value = value.replaceAll(",", "");
      value = value.replaceAll(".", "");
      if (value) {
        let offer = await counterOffer(this.propertyId, this.userId, value);
        this.$emit("changeOffer", offer);
      }
    },
  },
  emits: ["changeStatus"],
  props: {
    offerId: Number,
    propertyId: Number,
    userId: Number,
  },
};
</script>

<template>
  <div class="mt-10 d-flex justify-space-around">
    <v-btn
      class="mb-1"
      size="small"
      tile
      rounded
      variant="tonal"
      icon="mdi-check"
      color="success"
      @click="accept"
      v-tooltip:top="{
        text: 'Angebot akzeptieren',
      }"
    ></v-btn>
    <v-btn
      class="mb-1"
      size="small"
      rounded
      tile
      variant="tonal"
      icon="mdi-swap-horizontal"
      color="warning"
      @click="counter"
      v-tooltip:top="{
        text: 'Gegenangebot erstellen',
      }"
    ></v-btn>
    <v-btn
      class="mb-1"
      size="small"
      rounded
      variant="tonal"
      icon="mdi-cancel"
      tile
      color="red"
      @click="deny"
      v-tooltip:top="{
        text: 'Angebot ablehnen',
      }"
    ></v-btn>

    <v-btn
      size="small"
      tile
      rounded
      variant="tonal"
      color="grey"
      icon="mdi-clock-outline"
      @click="pedingOffer"
      v-tooltip:top="{
        text: 'Angebot auf wartend setzen',
      }"
    ></v-btn>
  </div>
</template>

<style scoped></style>
