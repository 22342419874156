<template>
  <v-card class="mb-5 mx-1 pa-3 black lighten-2" flat rounded>
    <div class="d-flex justify-space-between align-center mb-4">
      <h2 class="text-h6 font-weight-bold">Mein Makler Portfolio</h2>
    </div>
    <v-row>
      <v-col cols="12" sm="3" class="pa-1">
        <div class="d-flex flex-sm-column ga-2">
          <v-card flat class="pa-3 w-100 h-100 bg-grey-darken-35">
            <p class="text-disabled mb-0 flex-wrap">Immobilien</p>
            <p class="text-h5 font-weight-bold mb-0">
              <CountUp
                v-if="data.sumProperties"
                :end-value="data.sumProperties"
                :duration="1000"
              />
              <span v-else>0</span>
            </p>
          </v-card>
          <v-card flat class="pa-3 w-100 h-100 bg-grey-darken-35">
            <p class="text-disabled mb-0">Interessenten</p>
            <p class="text-h5 font-weight-bold mb-0">
              {{ data.sumAddedInterestUsers }}
            </p>
          </v-card>
        </div>
      </v-col>
      <v-col class="pa-1" cols="12" sm="9">
        <v-card
          flat
          class="d-flex align-center justify-center pa-2 bg-grey-darken-35 h-100"
        >
          <span class="position-absolute left-0 top-0 text-disabled px-3 py-1"
            >Marktwert</span
          >
          <BarChart
            v-if="data.sumGrossPrice"
            label-name="EUR"
            index-axis="y"
            :data="chartData"
            show-x
          ></BarChart>
          <span
            class="font-weight-medium my-5 text-disabled d-flex align-center justify-center"
            v-else
            >Nicht genügend Daten vorhanden</span
          >
        </v-card>
      </v-col>
      <v-col class="pa-1" cols="12" sm="6" md="12" lg="6">
        <v-card
          flat
          class="bg-grey-darken-35 h-100 d-flex flex-column overflow-visible"
        >
          <v-chip
            v-if="data.sumReceivedNewOffers > 0"
            v-bind="props"
            v-tooltip="{
              text: `${data.sumReceivedNewOffers} ${
                data.sumReceivedNewOffers === 1 ? 'Neues Gebot' : 'Neue Gebote'
              }`,
              openDelay: 500,
            }"
            color="accent"
            variant="elevated"
            class="position-absolute top-0 right-0 ma-n1"
            size="small"
          >
            <span class="font-weight-medium">{{
              data.sumReceivedNewOffers
            }}</span>
          </v-chip>

          <p
            v-if="offers.length > 0"
            class="text-body-1 text-left text-disabled ma-2"
          >
            Erhaltene Angebote
          </p>
          <p
            v-else
            class="text-body-1 text-center font-weight-medium text-disabled ma-2"
          >
            Keine Angebote vorhanden
          </p>
          <div class="overflow-y-hidden pa-1">
            <v-row class="flex-nowrap" style="width: max-content">
              <v-col
                v-for="(offer, index) in offers"
                :key="index"
                cols="auto"
                class="pa-0 pl-2 pr-2 py-2 ml-1 my-2"
              >
                <v-card
                  @click="
                    $router.push({
                      path: `agent/property/${offer.propertyId}`,
                      query: { tab: 'verkauf' },
                    })
                  "
                  v-tooltip:top="{
                    text: 'Klicken, um Angebot anzusehen',
                    openDelay: 2000,
                  }"
                  class="pa-2 bg-grey-darken-4 offer-tile"
                >
                  <div class="d-flex ga-1 align-center mb-1">
                    <span class="text-caption">{{ offer.label }}</span>
                    <v-chip
                      :color="chipColor(offer.status)"
                      size="x-small"
                      class="font-weight-medium"
                    >
                      <EnumDisplay
                        :enumObject="offerStatus"
                        :value="offer.status"
                      />
                    </v-chip>
                  </div>
                  <p
                    class="text-subtitle-2 d-flex justify-space-between align-center text-left mb-0"
                    :class="{
                      'text-decoration-line-through text-grey':
                        offer.counterOffer || offer.status === 'DECLINED',
                      'font-weight-bold':
                        !offer.counterOffer || !offer.status === 'DECLINED',
                    }"
                  >
                    <CurrencyDisplay :number="offer.purchasePrice" />
                    <!-- <v-icon size="small" color="grey-darken-3">mdi-pencil</v-icon> -->
                  </p>
                  <div
                    v-if="offer.counterOffer"
                    class="text-subtitle-2 text-left font-weight-bold mb-0 d-flex justify-space-between align-center"
                  >
                    <CurrencyDisplay :number="offer.counterOffer" />
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>

      <v-col class="pa-1" cols="12" sm="6" md="12" lg="6">
        <div class="bg-grey-darken-35 rounded pt-2">
          <span class="text-body-1 font-weight-bold">Termine</span>
          <div class="d-flex justify-center align-center ga-2">
            <v-card
              flat
              class="w-100 pa-3 border border-accent border-md ma-2 bg-grey-darken-35"
            >
              <p class="text-disabled mb-0">Intern</p>
              <p class="text-h5 font-weight-bold mb-0">
                {{ data.sumAppointmentsInternal }}
              </p>
            </v-card>

            <v-card
              flat
              class="ma-2 border border-accent border-2 border-md w-100 pa-3 bg-grey-darken-35"
            >
              <p class="text-disabled mb-0">Extern</p>
              <p class="text-h5 font-weight-bold mb-0">
                {{ data.sumAppointmentsExternal }}
              </p>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import BarChart from "@/components/data/BarChart.vue";
import CountUp from "@/components/helpers/CountUp.vue";
import { useNotificationStore } from "@/stores/modules/notification.module";
import ApiService from "@/services/api.service";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import EOfferStatus from "@/components/enums/EOfferStatus.js";
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";

export default {
  name: "AgentInsights",
  components: {
    BarChart,
    CountUp,
    EnumDisplay,
    CurrencyDisplay,
  },

  setup() {
    const notificationStore = useNotificationStore();
    return {
      notificationStore,
    };
  },
  data() {
    return {
      chartData: [],
      data: {},
      offers: [],
      offerStatus: EOfferStatus,
    };
  },
  methods: {
    async getAgentInsights() {
      this.loading = true;
      try {
        const response = await ApiService.get("dashboard/transaction");
        this.data = response.data;
        this.offers = this.data.offers;

        if (this.data.sumProperties > 1) {
          this.chartData = [
            {
              label: "Marktwert (gesamt)",
              average: this.data.averageMarketValue,
              value: this.data.sumGrossPrice,
            },
          ];
        } else {
          this.chartData = [
            {
              label: "Marktwert (gesamt)",
              value: this.data.sumGrossPrice,
            },
          ];
        }
      } catch (error) {
        this.notificationStore.pushError("Fehler beim Laden der Daten.");
      } finally {
        this.loading = false;
      }
    },
    chipColor(status) {
      switch (status) {
        case "PENDING":
          return "yellow";
        case "DECLINED":
          return "red";
        case "WITHDRAWED":
          return "red";
        case "ACCEPTED":
          return "green";
        case "COUNTER_OFFER":
          return "blue";
        default:
          return "grey";
      }
    },
  },
  async created() {
    await this.getAgentInsights();
  },
};
</script>

<style scoped>
.bg-grey-darken-35 {
  background-color: #282727;
}

.v-col {
  padding: 0;
}
</style>
