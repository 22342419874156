<template>
  <div>
    <v-carousel
      v-if="isRoleAdmin"
      class="mt-1 pb-5 bg-grey-darken-4 rounded"
      color="white"
      :show-arrows="false"
      height="100%"
      hide-delimiter-background
    >
      <v-carousel-item><AdminInsights /></v-carousel-item>
      <v-carousel-item>
        <UserInsights />
      </v-carousel-item>
      <v-carousel-item>
        <AgentInsights />
      </v-carousel-item>
    </v-carousel>
    <AgentInsights v-if="isRoleAgent && !isRoleAdmin && !isRoleUser" />
    <UserInsights v-if="isRoleUser && !isRoleAdmin && !isRoleAgent" />
    <v-row>
      <v-col cols="12" sm="6" md="12" lg="6">
        <v-sheet rounded class="my-5 pa-5 black lighten-2">
          <div v-if="files && files.length > 0">
            <v-list-item class="pa-0 mb-5" align="left">
              <template #title>Neue Dokumente</template>
              <template #subtitle>Eingang</template>
              <template #prepend>
                <div
                  class="rounded"
                  style="
                    padding: 10px;
                    margin-right: 15px;
                    background-color: white;
                  "
                >
                  <v-icon color="accent">mdi-file-document-multiple</v-icon>
                </div>
              </template>
            </v-list-item>
            <div v-for="(file, index) of files" :key="file.id">
              <v-list-item
                class="mt-2 ma-0 pa-0 text-left"
                rounded
                :title="file.filename"
              >
                <template v-slot:prepend>
                  <v-icon
                    class="ma-0 pa-0"
                    :icon="getIcon(file.type)"
                    color="white"
                  ></v-icon>
                </template>
                <template v-slot:append>
                  <v-btn
                    icon="mdi-file-download-outline"
                    color="accent"
                    size="x-small"
                    tile
                    rounded
                    variant="outlined"
                    v-tooltip:top="{
                      text: 'Datei herunterladen',
                      openDelay: 1000,
                    }"
                    @click="downloadFile(file.id, file.filename)"
                  ></v-btn>
                </template>

                <div
                  v-for="tag of file.tags"
                  :key="tag.key"
                  size="x-small"
                  color="grey"
                  class="text-caption mr-1 bg-grey-darken-3 px-2 text-grey-lighten-1 rounded-pill d-inline-block text-truncate"
                  style="max-width: 45%"
                  v-tooltip:top="{
                    text: tag.name,
                    openDelay: 300,
                  }"
                >
                  {{ tag.name }}
                </div>
              </v-list-item>

              <v-divider
                class="mx-6 mt-2"
                v-if="index < files.length - 1"
              ></v-divider>
            </div>
          </div>
          <div class="d-flex flex-column align-center ga-3 text-center" v-else>
            <v-icon color="grey-lighten-1" size="large"
              >mdi-file-check-outline</v-icon
            >
            <span>Keine Dokumente vorhanden</span>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="6" md="12" lg="6">
        <v-sheet
          rounded
          class="d-flex flex-column ga-6 justify-space-between my-0 my-sm-5 my-md-0 my-lg-5 pa-5 text-left black lighten-2"
        >
          <div>
            <MeetingItem
              v-if="closestAppointment"
              :meeting="closestAppointment"
            />
            <div
              class="d-flex flex-column align-center ga-3 text-center"
              v-else
            >
              <v-icon color="grey-lighten-1" size="large"
                >mdi-calendar-check-outline</v-icon
              >
              <span>Keine bevorstehenden Termine</span>
            </div>
          </div>
          <div
            v-if="closestAppointment"
            class="my-5 d-flex flex-wrap ga-3 justify-space-between"
          >
            <div class="d-flex ga-1">
              <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    size="small"
                    icon="mdi-clock-outline"
                    v-tooltip:top="{
                      text: 'Neue Zeit vorschlagen',
                      openDelay: 1000,
                    }"
                    tile
                    rounded
                    color="accent"
                    :variant="suggestButtonVariant"
                    :loading="loading"
                  ></v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card title="Neue Zeit vorschlagen">
                    <v-card-text>
                      <div class="py-5">
                        <KreaInput
                          type="datetime-local"
                          label="Start"
                          v-model:input-value="suggest.start"
                        />
                        <KreaInput
                          type="datetime-local"
                          label="Ende"
                          v-model:input-value="suggest.end"
                        />
                      </div>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn
                        text="Abbrechen"
                        @click="isActive.value = false"
                      ></v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        class="mx-auto"
                        color="accent"
                        :loading="loading"
                        @click="
                          handleAppointmentActionClick('suggest', suggest);
                          isActive.value = false;
                        "
                        :disabled="!suggest.start || !suggest.end"
                        >Senden</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>

              <v-btn
                @click="handleAppointmentActionClick('decline', false)"
                size="small"
                icon="mdi-cancel"
                v-tooltip:top="{
                  text: 'Termin ablehnen',
                  openDelay: 1000,
                }"
                tile
                rounded
                :loading="loading"
                color="accent"
                :variant="declineButtonVariant"
              ></v-btn>

              <v-btn
                @click="handleAppointmentActionClick('accept', false)"
                size="small"
                icon="mdi-check"
                :loading="loading"
                v-tooltip:top="{
                  text: 'Termin annehmen',
                  openDelay: 1000,
                }"
                tile
                rounded
                color="accent"
                :variant="acceptButtonVariant"
              ></v-btn>
            </div>
            <v-btn
              v-if="closestAppointment.host.id === userId"
              @click="cancelAppointment"
              color="red-darken-3"
              variant="tonal"
              :loading="loading"
              size="large"
              class="text-capitalize"
              >Absagen</v-btn
            >
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KreaInput from "@/components/KreaInput.vue";
import {
  getClosestAppointment,
  sendAppointmentAction,
} from "@/services/appointment.service";
import MeetingItem from "@/components/meetings/MeetingItem.vue";
import ApiService from "@/services/api.service";
import { useNotificationStore } from "@/stores/modules/notification.module";
import NotificationElephant from "@/models/notification";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import UserInsights from "@/components/dashboard/UserInsights.vue";
import AgentInsights from "@/components/dashboard/AgentInsights.vue";
import AdminInsights from "@/components/dashboard/AdminInsights.vue";
export default {
  setup() {
    const notificationStore = useNotificationStore();
    const authUserStore = useAuthUserStore();
    const userId = authUserStore.user.id;
    const isRoleAdmin = authUserStore.hasRole(["ROLE_ADMIN"]);
    const isRoleAgent = authUserStore.hasRole(["ROLE_MAKLER"]);
    const isRoleUser = authUserStore.hasRole(["ROLE_USER"]);
    return {
      notificationStore,
      userId,
      isRoleAdmin,
      isRoleAgent,
      isRoleUser,
    };
  },
  name: "DashboardOverview",
  components: {
    MeetingItem,
    KreaInput,
    UserInsights,
    AgentInsights,
    AdminInsights,
  },
  data() {
    return {
      loading: false,
      closestAppointment: null,
      suggest: {
        start: null,
        end: null,
        timezone: null,
      },
      files: null,
    };
  },
  computed: {
    suggestButtonVariant() {
      const participant = this.closestAppointment.participants.find(
        (p) => p.user.id === this.userId
      );
      return participant && participant.acceptStatus === "NEW_TIME"
        ? "flat"
        : "outlined";
    },

    declineButtonVariant() {
      const participant = this.closestAppointment.participants.find(
        (p) => p.user.id === this.userId
      );
      return participant && participant.acceptStatus === "DECLINED"
        ? "flat"
        : "outlined";
    },
    acceptButtonVariant() {
      const participant = this.closestAppointment.participants.find(
        (p) => p.user.id === this.userId
      );
      return participant && participant.acceptStatus === "ACCEPTED"
        ? "flat"
        : "outlined";
    },
  },
  methods: {
    getIcon(fileType) {
      //   console.log("fileType:", fileType);
      switch (fileType) {
        case "application/pdf":
          return "mdi-file-pdf-box";
        case "image/png":
          return "mdi-file-image-outline";
        case "image/jpg":
          return "mdi-file-image-outline";
        case "image/jpeg":
          return "mdi-file-image-outline";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "mdi-file-document-outline";
        default:
          return "mdi-file-outline";
      }
    },
    async downloadFile(id, name) {
      try {
        const response = await ApiService.post(
          "file/get/bytes",
          { text: id },
          { responseType: "blob" }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); // set the file name and extension here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Herunterladen der Datei.",
          error
        );
      }
    },
    async getFiles() {
      try {
        const response = await ApiService.get("file/get/my/3");
        console.log("Files", response.data);
        this.files = response.data;
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Laden der Dateien.",
          error
        );
      }
    },
    async getClosestAppointment() {
      this.closestAppointment = await getClosestAppointment();
      console.log("Closest Appointment", this.closestAppointment);
    },
    async handleAppointmentActionClick(action, suggest) {
      this.loading = true;
      await sendAppointmentAction(this.closestAppointment.id, action, suggest);
      this.getClosestAppointment();
      this.loading = false;
    },
    async cancelAppointment() {
      this.loading = true;
      try {
        await ApiService.post(
          `appointment/cancel/${this.closestAppointment.id}`
        );
        this.getClosestAppointment();
        this.notificationStore.push(
          new NotificationElephant({
            message: "Termin erfolgreich abgesagt.",
            color: "SUCCESS",
          })
        );
      } catch (error) {
        this.notificationStore.pushError("Fehler beim Absagen des Termins.");
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getFiles();
    this.getClosestAppointment();
    let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.suggest.timezone = userTimezone;
  },
};
</script>

<style scoped></style>
