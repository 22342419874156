<template>
  <v-sheet
    @click="$router.push('/agent/property/' + property.id)"
    rounded
    style="position: relative; overflow: hidden"
    class="pa-0 hvr-shrink"
  >
    <v-row style="position: relative" class="no-margin">
      <v-col
        class="pa-5 d-flex flex-column flex-wrap justify-space-between"
        style="position: relative"
        cols="10"
        sm="6"
      >
        <small
          :class="
            $vuetify.display.smAndUp ? 'building-type' : 'building-type-mobile'
          "
          v-text="propertyType"
        ></small>
        <div class="text-left">
          <h3 class="mb-3">
            <span v-text="propertyC.label" class="mr-1"></span>
          </h3>
          <small>
            <span v-text="address.street"></span> <br />
            <span class="mr-1" v-text="address.zipCode"></span>
            <span v-text="address.city"></span><br />
            <span class="text-disabled"
              ><span v-text="propertyC.distance + ' km'"> </span> entfernt</span
            >
          </small>
          <br />
          <ExclusivePropertyBadge
            v-if="propertyC.exclusiveId"
            :userId="propertyC.exclusiveId"
          />
          <v-chip-group>
            <v-chip v-for="role of property.myRoles" size="small" :key="role.id"
              ><EnumDisplay
                :enum-object="relationsEnum"
                :value="role.relationType"
            /></v-chip>
          </v-chip-group>
          <v-chip-group>
            <AddLegalOwnerToProperty
              :legal-owner="propertyC.legalOwner"
              :property-id="propertyC.id"
              @changedOwner="(user) => (this.propertyC.legalOwner = user)"
            />
          </v-chip-group>

          <PropertyProcessState
            :property-id="propertyC.id"
            v-model:state="propertyC.processState"
          />
        </div>
        <div class="text-left">
          <p class="text-disabled">Marktwert</p>
          <p
            style="color: rgba(var(--v-theme-accent)); font-weight: bold;font-size:1.4rem"
            v-if="property.saleGrossPrice"
          >
            <CurrencyDisplay :number="property.saleGrossPrice" />
          </p>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        style="position: relative; max-height: 400px"
        class="pa-0 mt-sm-0 d-flex align-center"
      >
        <PropertyThumbnail :property-id="propertyC.id" />
      </v-col>
    </v-row>
    <div
      class="pa-1 d-flex flex-column"
      style="position: absolute; right: 0; top: 0; gap: 5px"
      cols="1"
    >
      <!-- <v-btn @click="$router.push('/property/1')" variant="plain" icon><v-icon class="">mdi-file-document-outline</v-icon></v-btn> -->

      <EditPropertyButton :property-id="property.id"> </EditPropertyButton>
      <PropertyFilesPopup :property="property">
        <template #activator="props">
          <v-btn
            v-tooltip:top="{
              text: 'Dokumente',
              openDelay: 1000,
            }"
            color="accent"
            size="small"
            v-bind="props"
            icon
            tile
            rounded
          >
            <v-icon size="small">mdi-file-document-multiple-outline</v-icon>
          </v-btn>
        </template>
      </PropertyFilesPopup>

      <AppointmentsOfProperty :property-id="property.id">
      </AppointmentsOfProperty>
      <ShowOnMapButton :property="propertyC" />
      <LogbookButton :property-id="property.id">
        <template #activator="props">
          <v-btn
            v-tooltip:top="{
              text: 'Logbuch',
              openDelay: 1000,
            }"
            color="accent"
            v-bind="props"
            size="small"
            icon
            tile
            rounded
          >
            <v-icon size="small">mdi-book-open-outline</v-icon>
          </v-btn>
        </template>
      </LogbookButton>
    </div>
  </v-sheet>
</template>

<script>
import AddLegalOwnerToProperty from "@/components/agent/property/AddLegalOwnerToProperty.vue";
import PropertyFilesPopup from "@/components/property/PropertyFilesPopup.vue";
import ShowOnMapButton from "@/components/property/ShowOnMapButton.vue";
import PropertyThumbnail from "@/components/property/PropertyThumbnail.vue";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";

import EPropertyRelationType from "@/components/enums/EPropertyRelationType";
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";
import EditPropertyButton from "@/components/property/EditPropertyButton.vue";
import LogbookButton from "@/components/logbook/LogbookButton.vue";
import ExclusivePropertyBadge from "@/components/agent/property/ExclusivePropertyBadge.vue";
import AppointmentsOfProperty from "@/components/agent/property/AppointmentsOfProperty.vue";
import PropertyProcessState from "@/components/agent/property/PropertyProcessState.vue";
export default {
  name: "PropertyItemAgent",
  components: {
    PropertyProcessState,
    AppointmentsOfProperty,
    ExclusivePropertyBadge,
    LogbookButton,
    EditPropertyButton,
    CurrencyDisplay,
    EnumDisplay,
    PropertyThumbnail,
    ShowOnMapButton,
    AddLegalOwnerToProperty,
    PropertyFilesPopup,
  },
  props: {
    property: Object,
  },
  data() {
    return {
      relationsEnum: EPropertyRelationType,
    };
  },
  mounted() {},
  computed: {
    propertyType() {
      switch (this.property.propertyType) {
        case "BUILDING":
          return "Gebäude";
        case "UNIT":
          return "Einheit";
        case "LAND":
          return "Grundstück";
        default:
          return this.property.propertyType;
      }
    },
    propertyC: {
      get() {
        return this.property;
      },
      set(value) {
        this.$emit("update:property", value);
      },
    },
    address() {
      return this.propertyC.address;
    },
  },
};
</script>

<style scoped>
.building-type {
  position: absolute;
  top: 50%;
  right: 0;
  color: white;
  transform: translateY(-50%) translateX(25%) rotate(270deg);
  font-size: 0.7rem;
  letter-spacing: 3px;
  color: lightgrey;
}
.building-type-mobile {
  position: absolute;
  bottom: 0;
  right: 30%;
  color: white;
  font-size: 0.7rem;
  letter-spacing: 3px;
  color: lightgrey;
  /* margin: -40px; */
}
.no-margin {
  margin: 0 !important;
}
</style>
