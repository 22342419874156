<template>
  <SideBoard v-model="showSideBoard">
    <template #content>
      <!-- <CreateBuilding
        @close="showSideBoard = false"
        @success="showSideBoard = false"
        v-if="clickedBuilding"
        :click-event="buildingClickEvent"
        :property="clickedBuilding"
      /> -->
      <v-sheet>
        <v-tabs grow v-model="tab">
          <v-tab value="gebäude" color="accent" text="Gebäude anlegen"></v-tab
          ><v-tab text="Grundstück anlegen" color="accent" value="land"></v-tab
        ></v-tabs>
        <v-window v-model="tab">
          <v-window-item value="gebäude">
            <CreateBuilding
              @close="showSideBoard = false"
              @success="showSideBoard = false"
              :property="clickedBuilding"
              :click-event="buildingClickEvent"
            />
          </v-window-item>
          <v-window-item class="pa-5" value="land"
            ><CreateLand
              :property="clickedBuilding"
              :click-event="buildingClickEvent"
              @close="showSideBoard = false"
              @success="showSideBoard = false"
          /></v-window-item>
        </v-window>
      </v-sheet>
    </template>
  </SideBoard>
  <InfoBoard :property="property" v-model="showInfo" />

  <MapComponent
    :shrink="showSideBoard || showInfo"
    @buildingClick="handleBuildingClick"
    @propertyClick="handlePropertyClick"
    v-model="location"
  ></MapComponent>
</template>

<script>
import MapComponent from "@/components/MapComponent.vue";
import InfoBoard from "@/components/InfoBoard.vue";
import SideBoard from "@/components/SideBoard";
import CreateBuilding from "@/components/property/CreateBuilding.vue";
import CreateLand from "@/components/property/create/CreateLand.vue";

export default {
  name: "HomeView",
  components: {
    CreateBuilding,
    CreateLand,
    SideBoard,
    InfoBoard,
    MapComponent,
  },
  data() {
    return {
      tab: 0,
      buildingClickEvent: null,
      property: null,
      showInfo: false,
      showSideBoard: false,
      clickedBuilding: null,
      location: {
        lat: 48.151128223000306,
        lng: 11.556244519262124,
        bearing: 0,
        pitch: 0,
        zoom: 2,
      },
    };
  },
  methods: {
    handleBuildingClick(e) {
      this.showSideBoard = true;
      if (e) this.buildingClickEvent = e;
      if (e.features) this.clickedBuilding = e.features[0];
    },
    handlePropertyClick(e) {
      const data = e.features[0];
      console.log("Property data", data);
      if (!data.dataAndFacts) {
        return;
      }
      this.showInfo = !this.showInfo;
      console.log(e.features[0]);
      this.property = e.features[0];
    },
  },
};
</script>
