<template>
  <v-container
    style="height: 100%; max-width: none"
    :class="{ 'pl-5': $vuetify.display.smAndDown }"
  >
    <v-row class="d-flex flex-wrap" style="height: 100%">
      <v-col :class="$vuetify.display.mdAndUp ? 'w-60' : 'w-100'">
        <div>
          <div class="d-flex" style="gap: 20px">
            <SearchComponent
              @update:adress="searchedAdress = $event"
              v-model:adress="searchedAdress"
              v-model="searchedLocation"
              density="default"
            />
            <CreateBuidlingPopup
              @success="success(searchedLocation)"
              v-if="searchedLocation"
              :property="searchedLocation"
              :click-event="clickEventObject"
            >
              <template #activator="{ activator }">
                <v-btn
                  v-bind="activator"
                  icon="mdi-home-plus"
                  color="accent"
                  size="large"
                  rounded
                  @click="showDialog = true"
                ></v-btn>
              </template>
            </CreateBuidlingPopup>
            <v-btn
              v-if="$vuetify.display.mdAndUp"
              :append-icon="mapStore.show ? 'mdi-close' : 'mdi-arrow-left'"
              v-tooltip:top="{
                text: mapStore.show ? 'Karte ausblenden' : 'Karte einblenden',
                openDelay: 1000,
              }"
              height="55"
              @click="toggleMap"
              >Karte</v-btn
            >
          </div>
          <h2 class="text-left mb-4">Immobilien</h2>
          <div>
            <p
              class="text-left caption text-grey mb-2"
              v-if="!searchedLocation"
            >
              Entfernung zu deinem aktuellen Standort
            </p>
            <p class="text-left caption text-grey mb-1" v-else>
              Entfernung zu {{ searchedAdress }}
            </p>
          </div>
          <div ref="content">
            <PropertyListAgent />
          </div>
        </div>
      </v-col>
      <v-col
        v-if="$vuetify.display.mdAndUp"
        v-show="mapStore.show"
        ref="mapCol"
        class="animated w-40"
      >
        <MapComponent :hide-search="true" :popup="true" v-model="location" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MapComponent from "@/components/MapComponent";
import { useMapStore } from "@/stores/modules/map.module";
import {
  getAllPropertiesByApplicationState,
  getProperties,
  getPropertyStates,
} from "@/services/property.service";
import PropertyListAgent from "@/components/agent/property/PropertyListAgent.vue";
import SearchComponent from "@/components/map/SearchComponent.vue";
import { usePropertyStore } from "@/stores/modules/property.module";
import { useMaklerPropertyStore } from "@/stores/modules/makler.property.module";
import CreateBuidlingPopup from "@/components/property/create/CreateBuidlingPopup";

export default {
  name: "AgentPropertyView",
  components: {
    SearchComponent,
    PropertyListAgent,
    MapComponent,
    CreateBuidlingPopup,
  },
  setup() {
    const mapStore = useMapStore();
    const propertyStore = usePropertyStore();
    const maklerPropertyStore = useMaklerPropertyStore();

    return {
      mapStore,
      propertyStore,
      maklerPropertyStore,
    };
  },
  watch: {
    searchedLocation: {
      handler() {
        this.fetch(this.searchedLocation);
      },
    },
  },

  computed: {
    archivedProperties() {
      return this.maklerPropertyStore.archivedProperties;
    },
    availableProperties() {
      return this.maklerPropertyStore.availableProperties;
    },
    agentProperties() {
      return this.maklerPropertyStore.agentProperties;
    },
  },
  methods: {
    success(location) {
      console.log("reload");
      this.maklerPropertyStore.fetchAvailableProperties(
        0,
        location.geometry.coordinates
      );
    },
    fetch(location) {
      let coordinates = location.geometry.coordinates;
      this.clickEventObject = {
        lngLat: {
          lng: coordinates[0],
          lat: coordinates[1],
        },
      };
      this.maklerPropertyStore.fetchAgentProperties(0, coordinates);
      this.maklerPropertyStore.fetchAvailableProperties(0, coordinates);
      this.maklerPropertyStore.fetchArchivedProperties(0, coordinates);
    },
    toggleMap() {
      this.mapStore.toggleMap();
      setTimeout(() => {
        this.mapStore.map.resize();
      }, 50);
    },
    async getArchivedProperties(page) {
      this.archivedProperties.loading = true;
      let response = await getAllPropertiesByApplicationState(
        "ARCHIVED",
        page,
        this.mapStore.userPosition
      );
      let properties = response.content;
      let currentPage = response;
      this.archivedProperties.content = properties;
      this.archivedProperties.currentPage = currentPage;
      this.archivedProperties.loading = false;
    },
    async getProperties() {
      this.properties = await getProperties();
    },
    async getPropertyStates() {
      this.propertyStates = await getPropertyStates();
    },
  },
  data() {
    return {
      showDialog: false,
      searchedLocation: null,
      searchedAdress: null,
      clickEventObject: null,
      propertyStates: [],
      properties: [],
      location: {
        lat: 48.151128223000306,
        lng: 11.556244519262124,
        bearing: 0,
        pitch: 0,
        zoom: 2,
      },
    };
  },
};
</script>

<style scoped>
.map-smaller {
  height: 200px !important;
}
.animated {
  transition: width, height 0.5s ease-in-out;
}
.content-larger {
  width: 80vw !important;
  max-width: 1100px !important;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
.v-container {
  padding-top: 0;
  padding-left: 0;
}
</style>
