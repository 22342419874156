<template>
  <div class="pa-5">
    <AddInterestToProperty
      @newInterestedUser="addNewUser"
      :agent="agent"
      :property-id="propertyId"
    />
    <v-list
      style="background-color: transparent"
      color="transparent"
      v-if="interestedUsers.length > 0"
    >
      <InterestedUserListItem
        v-for="user of interestedUsers"
        :key="user.id"
        :interested-user="user"
      />
    </v-list>
    <div
      style="min-height: 200px"
      class="d-flex justify-center align-center"
      v-else
    >
      <span>Keine Interessenten</span>
    </div>
  </div>
</template>

<script>
import AddInterestToProperty from "@/components/interest/AddInterestToProperty.vue";
import { getInterestsOfProperty } from "@/services/saleProcess/interest.service";
import InterestedUserListItem from "@/components/interest/InterestedUserListItem";
export default {
  name: "InterestedUsersList",
  components: { InterestedUserListItem, AddInterestToProperty },
  data() {
    return {
      interestedUsers: [],
    };
  },
  async created() {
    this.getInterestList();
  },
  props: {
    agent: Boolean,
    propertyId: String,
  },
  methods: {
    async getInterestList() {
      if (this.propertyId)
        this.interestedUsers = await getInterestsOfProperty(this.propertyId);
    },
    addNewUser(user) {
      this.interestedUsers.push(user);
    },
  },
};
</script>

<style scoped></style>
