<template>
  <div class="rounded ma-3 elevation-3">
    <v-dialog max-width="500">
      <template v-slot:activator="{ props: activatorProps }">
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card
              :ripple="false"
              v-if="propertyState.mandant"
              class="bg-grey-darken-35 d-flex flex-row justify-start text-left rounded py-3 pr-3"
              v-bind="{ ...activatorProps, ...props }"
            >
              <template #prepend>
                <UserAvatar :size="50" :user="propertyState.mandant" />
              </template>
              <div class="d-flex flex-column">
                <v-card-title class="ma-0 pa-0">
                  <span
                    class="text-body-1 text-left text-grey-lighten-2"
                    v-text="propertyState.mandant.name"
                  ></span>
                  <v-chip :color="offerColor.color" size="small" class="ml-1">
                    <EnumDisplay
                      :enumObject="offerStates"
                      :value="propertyState.offer.status"
                    /> </v-chip
                ></v-card-title>
                <v-card-text class="ma-0 pa-0">
                  <p
                    class="d-flex justify-space-between align-center text-left mb-0"
                    :style="{
                      'font-size':
                        !propertyState.offer.counterOffer &&
                        propertyState.offer.status !== 'declined'
                          ? '1.05rem'
                          : 'inherit',
                    }"
                    :class="{
                      'text-decoration-line-through text-grey':
                        propertyState.offer.counterOffer ||
                        propertyState.offer.status === 'DECLINED',
                      'font-weight-bold':
                        !propertyState.offer.counterOffer ||
                        !propertyState.offer.status === 'DECLINED',
                    }"
                  >
                    <CurrencyDisplay
                      :number="propertyState.offer.purchasePrice"
                    />
                  </p>
                  <div
                    v-if="propertyState.offer.counterOffer"
                    style="font-size: 1.05rem"
                    class="text-left font-weight-bold mb-0 d-flex justify-space-between align-center"
                  >
                    <CurrencyDisplay
                      :number="propertyState.offer.counterOffer"
                    />
                  </div>
                </v-card-text>
              </div>
              <v-icon
                v-if="isHovering"
                class="position-absolute bottom-0 right-0 mr-1 mb-1"
                size="x-small"
                color="grey-darken-1"
                >mdi-pencil</v-icon
              >
            </v-card>
          </template>
        </v-hover>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card>
          <v-card-text class="px-5">
            <v-list-item class="mt-13 mx-0 px-0" v-if="propertyState.mandant">
              <template #prepend>
                <UserAvatar :size="50" :user="propertyState.mandant" />
              </template>
              <template #title>
                <span v-text="propertyState.mandant.name"></span>
              </template>
              <template #subtitle>
                <div>
                  <p>
                    <span>Kaufintention </span
                    ><span
                      ><span v-text="propertyState.interest.buyIntention"></span
                      >/10</span
                    >
                  </p>
                  <p v-text="propertyState.interest.note"></p>
                </div>
              </template>
            </v-list-item>
            <div class="my-5 d-flex justify-space-between">
              <v-textarea
                class="text-grey font-italic w-100"
                placeholder="Notiz hinzufügen..."
                prepend-inner-icon="mdi-note-outline"
                :variant="showSaveNoteBtn ? 'solo' : 'plain'"
                rows="1"
                auto-grow
                max-rows="3"
                text="hallo test"
                v-model="propertyStateC.notes"
                density="comfortable"
                @click="showSaveNoteBtn = true"
                @blur="hideSaveButton"
                ><template v-if="showSaveNoteBtn" #append-inner>
                  <v-divider inset vertical></v-divider>
                  <v-btn
                    class="ma-0 ml-3"
                    icon="mdi-check"
                    color="white"
                    size="small"
                    variant="plain"
                    rounded
                    tile
                    @click="saveNotes"
                  ></v-btn>
                  <v-btn
                    class="ma-0"
                    icon="mdi-trash-can-outline"
                    color="white"
                    size="small"
                    variant="plain"
                    rounded
                    tile
                    @click="
                      propertyStateC.notes = '';
                      hideSaveButton();
                      saveNotes();
                    "
                  ></v-btn></template
              ></v-textarea>
            </div>

            <v-table>
              <tbody>
                <tr
                  :class="
                    propertyStateC.offer.status === 'COUNTER_OFFER'
                      ? 'text-decoration-line-through'
                      : ''
                  "
                >
                  <td>Angebotspreis</td>
                  <td
                    :class="{
                      'font-weight-bold': !propertyState.offer.counterOffer,
                    }"
                  >
                    <CurrencyDisplay
                      :number="propertyState.offer.purchasePrice"
                    />
                  </td>
                </tr>
                <tr
                  v-if="
                    propertyStateC.offer.status === 'COUNTER_OFFER' &&
                    propertyState.offer.counterOffer
                  "
                >
                  <td>Gegenangebot</td>
                  <td class="font-weight-bold">
                    <CurrencyDisplay
                      :number="propertyState.offer.counterOffer"
                    />
                  </td>
                </tr>
                <tr>
                  <td>Gültig bis:</td>
                  <td>
                    <span class="mr-1" v-text="validUntil.date"></span>
                    <span>
                      <v-icon
                        v-tooltip:top="{
                          text: 'Angebot abgelaufen',
                        }"
                        color="error"
                        v-if="validUntil.state === 'EXPIRED'"
                        >mdi-close-circle</v-icon
                      >
                      <v-icon
                        v-tooltip:top="{
                          text: 'Angebot läuft bald ab',
                        }"
                        color="warning"
                        v-if="validUntil.state === 'EXPIRES_SOON'"
                        >mdi-alert-circle</v-icon
                      >
                      <v-icon
                        v-tooltip:top="{
                          text: 'Angebot gültig',
                        }"
                        color="success"
                        v-if="validUntil.state === 'VALID'"
                        >mdi-check-circle</v-icon
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>
                    <EnumDisplay
                      :enum-object="offerStates"
                      :value="propertyState.offer.status"
                    />
                  </td>
                </tr>
              </tbody>
            </v-table>
            <OfferActionButtonsOwner
              @changeOffer="propertyStateC.offer = $event"
              @changeStatus="propertyStateC.offer.status = $event"
              :offerId="propertyStateC.offer.id"
              :user-id="propertyStateC.offer?.offerFrom"
              :propertyId="propertyState.property.id"
            />
          </v-card-text>

          <v-card-actions>
            <div
              class="text-disabled text-caption position-absolute top-0 left-0 ma-4"
            >
              {{ propertyState.property.label }} - Objekt ID
              {{ propertyState.property.id }}
            </div>
            <div class="position-absolute top-0 right-0 ma-1">
              <PropertyFilesPopup
                :property="propertyStateC.property"
                size="small"
                color="grey-lighten-1"
              />
              <AppointmentsOfSaleProcess
                :property-id="propertyState.property.id"
                :user-id="propertyState.mandant.id"
                size="small"
                color="grey-lighten-1"
              />
              <v-btn
                icon="mdi-close"
                color="grey-lighten-1"
                @click="isActive.value = false"
                v-tooltip:top="{
                  text: 'Schließen',
                  openDelay: 1500,
                }"
              ></v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import UserAvatar from "@/components/user/Avatar.vue";
import CurrencyDisplay from "@/components/helpers/CurrencyDisplay.vue";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import EOfferStatus from "@/components/enums/EOfferStatus";
import { changesNotes } from "@/services/saleProcess/process.service";
import { useMapStore } from "@/stores/modules/map.module";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import AppointmentsOfSaleProcess from "@/components/agent/property/AppointmentsOfSaleProcess.vue";
import moment from "moment/moment";
// import SelectEnum from "@/components/form/SelectEnum.vue";
import { changeSaleStatus } from "@/services/saleProcess/sale.service";
// import ShowOnMapButton from "@/components/property/ShowOnMapButton.vue";
import PropertyFilesPopup from "@/components/property/PropertyFilesPopup.vue";
import OfferActionButtonsOwner from "@/components/property/offer/OfferActionButtonsOwner.vue";

export default {
  name: "PropertyItemState",

  components: {
    OfferActionButtonsOwner,
    PropertyFilesPopup,
    // ShowOnMapButton,
    // SelectEnum,
    AppointmentsOfSaleProcess,
    EnumDisplay,
    CurrencyDisplay,
    UserAvatar,
  },
  setup() {
    const mapStore = useMapStore();
    const userStore = useAuthUserStore();
    return { mapStore, userStore };
  },
  props: {
    propertyState: Object,
  },
  emits: ["update:propertyState"],
  methods: {
    hideSaveButton() {
      setTimeout(() => {
        this.showSaveNoteBtn = false;
      }, 200);
    },
    async updateSaleState() {
      let newStatus = await changeSaleStatus(
        this.propertyState.property.id,
        this.propertyState.sale.sellerId,
        this.propertyState.sale.status
      );
      this.propertyStateC.sale.status = newStatus;
    },
    showOnMap() {
      this.mapStore.flyToBuilding(this.propertyState.property);
    },
    async saveNotes() {
      console.log("saveNotes", this.propertyStateC.notes);
      this.editNotes = false;
      this.propertyStateC.notes = await changesNotes(
        this.propertyStateC.id,
        this.propertyStateC.notes
      );
    },
  },
  computed: {
    currentUser() {
      return this.userStore.user;
    },
    propertyStateC: {
      get() {
        return this.propertyState;
      },
      set(value) {
        this.$emit("update:propertyState", value);
      },
    },
    validUntil() {
      moment.locale("de");
      let date = moment(this.propertyState.offer.validUntil);
      let state = "VALID";
      if (date.isBefore(moment())) {
        state = "EXPIRED";
      } else if (date.isBefore(moment().add(7, "days"))) {
        state = "EXPIRES_SOON";
      } else {
        state = "VALID";
      }
      return {
        date: date.format("LL"),
        state: state,
      };
    },
    offerColor() {
      let offer = this.propertyState.offer;
      if (offer) {
        switch (offer.status) {
          case "PENDING":
            if (this.validUntil.state === "EXPIRED")
              return {
                color: "error",
                textColor: "white",
                icon: "mdi-close",
              };
            else if (this.validUntil.state === "EXPIRES_SOON")
              return {
                color: "warning",
                textColor: "white",
                icon: "mdi-alert",
              };
            else {
              return {
                color: "accent",
                textColor: "white",
                icon: "mdi-progress-question",
              };
            }
          case "ACCEPTED":
            return {
              color: "success",
              textColor: "white",
              icon: "mdi-check",
            };
          case "DECLINED":
            return {
              color: "error",
              textColor: "white",
              icon: "mdi-close",
            };
          case "WITHDRAWED":
            return {
              color: "error",
              textColor: "white",
              icon: "mdi-close",
            };
          case "COUNTER_OFFER":
            return {
              color: "warning",
              textColor: "white",
              icon: "mdi-cog",
            };
        }
      } else {
        return {
          color: "grey",
          textColor: "white",
          icon: "mdi-sleep",
        };
      }
      return {
        color: "grey",
        textColor: "white",
        icon: "mdi-sleep",
      };
    },
    saleColor() {
      let sale = this.propertyState.sale;
      if (sale) {
        switch (sale.status) {
          case "PENDING":
            return {
              color: "grey",
              textColor: "white",
              icon: "mdi-sleep",
            };
          case "IN_PROGRESS":
            return {
              color: "accent",
              textColor: "white",
              icon: "mdi-cog",
            };
          case "SOLD":
            return {
              color: "success",
              textColor: "white",
              icon: "mdi-check",
            };
          case "ARCHIVED":
            return {
              color: "grey",
              textColor: "white",
              icon: "mdi-archive",
            };
        }
      } else {
        return {
          color: "grey",
          textColor: "white",
          icon: "mdi-sleep",
        };
      }
      return {
        color: "grey",
        textColor: "white",
        icon: "mdi-sleep",
      };
    },
  },
  data() {
    return {
      offerStates: EOfferStatus,
      editNotes: false,
      showSaveNoteBtn: false,
    };
  },
};
</script>

<style scoped>
.bg-grey-darken-35 {
  background-color: #161616;
}
</style>
