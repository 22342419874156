const ELongListState = {
  PENDING: { de: "Offen", en: "pending" },
  WAITING_FOR_RESPONSE: {
    de: "Rückmeldung ausstehend",
    en: "waiting for response",
  },
  NO_RESPONSE: { de: "Keine Rückmeldung", en: "no response" },
  INTERESTED: { de: "Interessiert", en: "interested" },
  IRRELEVANT: { de: "Irrelevant", en: "irrelevant" },
};
export default ELongListState;
