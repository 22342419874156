<script>
import { getAppointmentByUserAndProperty } from "@/services/appointment.service";
import AppointmentList from "@/components/appointment/AppointmentList.vue";
import CreateAppointmentDialog from "@/components/appointment/CreateAppointmentDialog.vue";

export default {
  name: "AppointmentsOfSaleProcess",
  components: { CreateAppointmentDialog, AppointmentList },
  props: {
    propertyId: Number,
    userId: Number,
    type: String,
    size: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      appointments: [],
      show: false,
    };
  },
  created() {
    this.getAppointments();
  },
  methods: {
    async getAppointments() {
      this.appointments = await getAppointmentByUserAndProperty(
        this.userId,
        this.propertyId
      );
    },
  },
};
</script>

<template>
  <v-dialog v-model="show" max-width="550px">
    <template #activator="{ props }">
      <v-btn
        @click="getAppointments"
        v-bind="props"
        icon="mdi-calendar"
        :color="color"
        :size="size"
        v-tooltip="{
          text: 'Termine',
          openDelay: 500,
        }"
      ></v-btn>
    </template>
    <v-card>
      <v-card-text>
        <AppointmentList :appointments="appointments" />
        <CreateAppointmentDialog
          @termin-created="
            (t) => {
              this.appointments.push(t);
            }
          "
          :type="type"
          :property-id="propertyId"
          :user-id="userId"
        >
          <template #activator="props">
            <v-btn color="accent" v-bind="props" block
              ><v-icon>mdi-calendar-plus</v-icon> Termin anlegen</v-btn
            >
          </template>
        </CreateAppointmentDialog>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
