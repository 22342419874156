<template>
  <div class="d-flex flex-wrap">
    <div
      class="px-5"
      :class="$vuetify.display.mdAndUp ? 'w-60' : 'w-100'"
      style="min-height: 100vh"
    >
      <div class="d-flex justify-start align-center">
        <v-btn
          v-if="isOwnerOrMakler"
          size="small"
          tile
          rounded
          color="accent"
          @click="$router.push('/property/' + id + '/edit')"
          v-tooltip="'Bearbeiten'"
          icon="mdi-pencil"
        ></v-btn>
        <v-tabs v-model="tab">
          <v-tab value="details">Details</v-tab>
          <v-tab value="wert">Marktwert</v-tab>
          <v-tab value="dokumente">Dokumente</v-tab>
          <v-tab value="mandatsvertrag" v-if="isOwner"
            >Mein Mandatsvertrag</v-tab
          >
          <v-tab value="verkaufsprozess" v-if="isOwner">Verkaufsprozess</v-tab>
          <v-tab v-if="isOwner" value="logbuch">Logbuch</v-tab>
          <v-tab value="angebot" v-if="!isOwner">Mein Angebot</v-tab>
        </v-tabs>
        <div v-if="isOwner" v-tooltip:start="'in Ihrem Besitz'">
          <v-btn
            readonly
            rounded
            density="comfortable"
            icon="mdi-crown"
            color="accent"
          ></v-btn>
        </div>
      </div>
      <v-window v-model="tab">
        <v-window-item value="details">
          <PropertyDetails :property-id="id" />
        </v-window-item>
        <v-window-item value="wert">
          <div class="py-5">
            <ValuationItem :property-id="id" />
          </div>
        </v-window-item>
        <v-window-item value="dokumente"
          ><DocumentUpload :propertyId="id" />
        </v-window-item>
        <v-window-item value="mandatsvertrag" v-if="isOwner">
          <MandateContractWrapper :propertyId="id" />
        </v-window-item>
        <v-window-item value="verkaufsprozess" v-if="isOwner">
          <SaleInfoForOwner :property-id="id" />
        </v-window-item>
        <v-window-item value="logbuch">
          <LogbookItem readonly :propertyId="id" />
        </v-window-item>
        <v-window-item value="angebot" v-if="!isOwner">
          <div class="pa-5">
            <OfferDetails :property-id="id" />
          </div>
        </v-window-item>
      </v-window>
    </div>
    <div v-if="$vuetify.display.mdAndUp" class="w-40" style="min-height: 100vh">
      <MapComponent :no-geolocate="true" v-model="location" />
    </div>
  </div>
</template>

<script>
import MapComponent from "@/components/MapComponent";
import PropertyDetails from "@/components/property/details/PropertyDetails";
import DocumentUpload from "@/components/property/documents/DocumentUpload";
import OfferDetails from "@/components/property/offer/OfferDetails.vue";
import LogbookItem from "@/components/logbook/LogbookItem.vue";
import { isOwnerOfProperty } from "@/services/user.service";
import MandateContractWrapper from "@/components/property/contract/MandateContractWrapper.vue";
import SaleInfoForOwner from "@/components/property/owner/SaleInfoForOwner.vue";
import { useMapStore } from "@/stores/modules/map.module";
import { getPointOfProperty } from "@/services/property.service";
import ValuationItem from "@/components/property/valuation/ValuationItem.vue";
import { useAuthUserStore } from "@/stores/modules/auth.module";
export default {
  setup() {
    const authStore = useAuthUserStore();
    const mapStore = useMapStore();
    return { mapStore, authStore };
  },
  name: "PropertyDetailsView",
  components: {
    ValuationItem,
    SaleInfoForOwner,
    MandateContractWrapper,
    OfferDetails,
    PropertyDetails,
    MapComponent,
    DocumentUpload,
    LogbookItem,
  },
  props: {
    id: String,
  },
  data() {
    return {
      activeTab: "angebot",
      isOwner: false,
      tab: 0,
      location: {
        lat: 48.151128223000306,
        lng: 11.556244519262124,
        bearing: 0,
        pitch: 0.2,
        zoom: 0,
      },
    };
  },

  methods: {
    isOwnerOrMakler() {
      return (
        this.isOwner || this.authStore.hasRole(["ROLE_MAKLER", "ROLE_ADMIN"])
      );
    },
    async flyToProperty() {
      console.log("Checking if there is a map: ", this.mapStore.hasMap);
      if (!this.mapStore.hasMap) {
        console.log("No map found");
        return;
      }
      const point = await getPointOfProperty(this.id);
      console.log("Point: ", point);
      this.location.lng = point[1];
      this.location.lat = point[0];
      this.mapStore.flyToPoint(point);
    },
  },
  async created() {
    this.isOwner = await isOwnerOfProperty(this.id);
    await this.flyToProperty();
    if (this.$route.query.tab) {
      this.tab = this.$route.query.tab;
    }
  },
};
</script>

<style scoped>
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
</style>
