export class CreateLandRequest {
  id;
  address;
  label;
  plotArea;
  createdBy;
  location;
  buildingId;

  constructor({
    id,
    address,
    label,
    plotArea,
    createdBy,
    location,
    buildingId,
  }) {
    this.id = id;
    this.address = address;
    this.label = label;
    this.plotArea = plotArea;
    this.createdBy = createdBy;
    this.location = location;
    this.buildingId = buildingId;
  }
}
