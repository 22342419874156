<script>
import UserContactCard from "@/components/user/UserContactCard.vue";

export default {
  name: "SimpleUserListItem",
  components: { UserContactCard },
  props: {
    user: Object,
  },
};
</script>

<template>
  <UserContactCard :user-id="user.id">
    <template #activator="props">
      <v-list-item
        v-bind="props"
        class="rounded text-left bg-grey-darken-4 pa-2"
        color="black"
        :subtitle="user.email"
      >
        <template #title>
          <div class="mb-1 d-flex justify-left align-center">
            <v-chip size="x-small" class="mr-1"
              ># <span v-text="user.id"></span></v-chip
            ><span v-text="user.name"></span>
          </div>
        </template>
      </v-list-item>
    </template>
  </UserContactCard>
</template>

<style scoped></style>
