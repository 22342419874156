import ApiService from "@/services/api.service"

export async function searchForUser(searchString){
    const response = await ApiService.get("user/search?s="+searchString);
    return response.data;
}

export async function inviteUser(request){
    const response = await ApiService.post("user/invite",request);
    return response.data;
}

export async function getUsersByIds(ids){
    const response = await ApiService.post("user/get/by/ids",ids);
    return response.data;
}

export async function addPropertyRelation(propertyId,relationType){
    const response = await ApiService.get("user/property/relation/add/"+propertyId+"/"+relationType);
    console.log("Respons",response);
    return response.data;
}

export async function isOwnerOfProperty(propertyId){
    const response = await ApiService.get("user/is/owner/of/"+propertyId);
    return response.data;
}

export async function removePropertyRelation(propertyId,relationType){
    const response = await ApiService.get("user/property/relation/remove/"+propertyId+"/"+relationType);
    return response.data;
}
export async function getSingleUser(id){
    const response = await ApiService.get("user/"+id+"/get");
    return response.data;
}

export async function getUsersForAdminByPage(page){
    const response = await ApiService.get("user/all/"+page);
    return response.data;
}

export async function findUsersForAdmin(searchString,page){
    const response = await ApiService.get("user/find/"+page+"?s="+searchString);
    return response.data;
}

export async function setRoles(adminUser){
    const response = await ApiService.post("user/roles/change",adminUser);
    return response.data;
}

export async function getAllRoles(){
    const response = await ApiService.get("role/all");
    return response.data;
}

export async function updateUser(adminUser){
    const response = await ApiService.post("user/update",adminUser);
    return response.data;
}

export async function getUserContactCard(userId) {
    const response = await ApiService.get("user/contact/card/" + userId);
    return response.data;
}

export async function disableUser(userId) {
    const response = await ApiService.get("user/disable/" + userId);
    return response.status === 200;
}

export async function enableUser(userId) {
    const response = await ApiService.get("user/enable/" + userId);
    return response.status === 200;
}

export async function deleteUser(userId) {
    const response = await ApiService.get("user/delete/" + userId);
    return response.status === 200;
}