<template>
<div class="">
  <div class="pa-10" v-if="!loading && images && images.length<=0">Keine Bilder vorhanden</div>
  <GalleryComponent v-if="!loading" :images="images"/>
  <div style="width:100%" v-else class="d-flex justify-start">
    <v-skeleton-loader height="150" width="150" type="image"/>
  </div>
</div>
</template>

<script>
import {loadGallery} from "@/services/gallery.service";
import GalleryComponent from "@/components/GalleryComponent.vue";

export default {
  name: "PropertyGalery",
  components: {GalleryComponent},
  props:{
    propertyId:String
  },
  data(){
    return {
      images: [],
      loading:false
    }
  },
  created() {
    this.getGalleryOfProperty();
  },
  methods:{
    async getGalleryOfProperty() {
      this.loading=true;
      const data = await loadGallery(this.propertyId);
      this.loading=false;
      this.images= data;
    }
  }
}
</script>

<style scoped>

</style>