<template>
  <v-app-bar
    class="pl-5"
    color="rgb(var(--v-theme-background))"
    flat
    height="100"
  >
    <template #prepend>
      <router-link to="/dashboard">
        <v-img
          :src="require('@/assets/rubikon_logo 1.png')"
          max-width="100"
          width="100"
          height="60"
        ></v-img>
      </router-link>
    </template>
    <div></div>
    <template #append>
      <!-- <v-btn icon>
        <v-icon>mdi-bell-outline</v-icon>
      </v-btn> -->
      <v-btn icon v-if="currentUser" @click="$router.push('/profile')">
        <UserAvatar :user="currentUser" />
      </v-btn>
      <v-btn v-else @click="$router.push('/a/login')" icon>
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>
      <v-btn icon @click="toggleNav">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { useNavStore } from "@/stores/modules/nav.module";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import UserAvatar from "@/components/user/Avatar";

export default {
  name: "TopBar",
  components: { UserAvatar },
  setup() {
    const navStore = useNavStore();
    const authStore = useAuthUserStore();
    return {
      navStore,
      authStore,
    };
  },
  computed: {
    currentUser() {
      return this.authStore.user;
    },
  },
  methods: {
    toggleNav() {
      this.navStore.toggleLeftDrawer();
    },
  },
};
</script>

<style scoped></style>
