<script>
import { useProfileStore } from "@/stores/modules/profile.store";

export default {
  name: "ProfileView",
  setup() {
    const profileStore = useProfileStore();
    profileStore.fetchProfile();
    return { profileStore };
  },
};
</script>

<template>
  <v-app-bar density="compact" elevation="0" class="text-left pr-5" order="3">
    <template #prepend>
      <v-btn @click="$router.back()" icon="mdi-arrow-left"></v-btn>
    </template>
    <template #title>
      {{ $route.name }}
    </template>
  </v-app-bar>
  <v-divider />
  <router-view />
</template>

<style scoped></style>
