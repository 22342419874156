<template>
  <v-skeleton-loader
    style="min-height: 200px; height: 100%; width: 100%"
    :loading="image.loading"
    type="image"
  >
    <v-img
      height="100%"
      cover
      v-if="image.data"
      :src="image.data"
      style="max-width: 100%"
    ></v-img>
    <v-img
      v-else
      cover
      height="100%"
      :src="require('@/assets/joel-filipe-jU9VAZDGMzs-unsplash.jpg')"
    ></v-img>
  </v-skeleton-loader>
</template>
<script>
import { getThumbnailOfProperty } from "@/services/gallery.service";

export default {
  name: "PropertyThumbnail",
  props: {
    propertyId: Number,
  },
  data() {
    return {
      image: {
        data: null,
        loading: false,
      },
    };
  },
  created() {
    this.loadThumbnail();
  },
  methods: {
    async loadThumbnail() {
      this.image.loading = true;
      try {
        const image = await getThumbnailOfProperty(this.propertyId);
        if (!image) {
          this.image.data = null;
        } else this.image.data = "data:image/webp;base64," + image.image;
      } catch (e) {
        console.error(e);
        this.image.data = null;
      }

      this.image.loading = false;
    },
  },
};
</script>
