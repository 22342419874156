<template>
  <v-container>
    <div :class="customClass">
      <section class="mt-5" v-if="hasRole || currentUserIsOwner">
        <v-form ref="form" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="12" md="7">
              <v-file-input
                color="accent"
                v-model="file"
                :rules="fileRules"
                label="Neues Dokument hochladen"
                show-size
                chips
                multiple
                required
              ></v-file-input>
            </v-col>

            <v-col cols="12" md="5">
              <v-autocomplete
                prepend-icon="mdi-tag-outline"
                color="accent"
                v-model="selectedTag"
                :items="tags"
                :rules="tagRules"
                label="Tags"
                item-title="name"
                item-value="key"
                multiple
                chips
                required
                clearable
              >
                <template v-slot:chip="{ props, item }">
                  <v-chip v-bind="props" :text="item.raw.name">
                    <template #prepend>
                      <v-sheet
                        color="transparent"
                        dark
                        class="px-1 ma-2 rounded"
                      >
                        <span style="font-size: 0.8rem">{{
                          item.raw.page
                        }}</span></v-sheet
                      >
                    </template>
                  </v-chip>
                </template>

                <template v-slot:item="{ props, item }">
                  <v-list-item v-bind="props" :title="item.raw.name">
                    <template #prepend>
                      <v-sheet
                        color="transparent"
                        dark
                        class="px-1 ma-2 rounded"
                      >
                        <span style="font-size: 0.8rem">{{
                          item.raw.page
                        }}</span></v-sheet
                      >
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-btn block color="accent" :loading="isLoading" type="submit"
            >Dokument hochladen</v-btn
          >
        </v-form>
      </section>
      <section class="mt-10" v-if="hasRole || currentUserIsOwner">
        <v-tabs v-model="tab" grow>
          <v-tab text="Alle Dokumente"></v-tab>

          <v-tab text="Berechtigung verwalten"></v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item>
            <DocumentList :files="files" :allTags="tags" />
          </v-window-item>
          <v-window-item>
            <ManageTagPermission :propertyId="propertyId" />
          </v-window-item>
        </v-window>
      </section>
      <DocumentList v-else customClass="mx-0" :files="files" :allTags="tags" />
    </div>
  </v-container>
</template>

<script>
import ApiService from "@/services/api.service.js";
import DocumentList from "@/components/property/documents/DocumentList";
import ManageTagPermission from "./ManageTagPermission.vue";
import { fetchFiles, fetchTags } from "@/services/property.service.js";
import { useAuthUserStore } from "@/stores/modules/auth.module.js";
import { isOwnerOfProperty } from "@/services/user.service";
import { useNotificationStore } from "@/stores/modules/notification.module";

export default {
  setup() {
    const authStore = useAuthUserStore();
    const hasRole = authStore.hasRole(["ROLE_ADMIN", "ROLE_MAKLER"]);
    const notificationStore = useNotificationStore();

    return {
      authStore,
      hasRole,
      notificationStore,
    };
  },
  name: "DocumentUpload",
  components: { DocumentList, ManageTagPermission },
  props: {
    propertyId: String,
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tab: 0,
      currentUserIsOwner: false,
      files: [],
      file: null,
      isLoading: false,
      tags: [],
      selectedTag: null,
      fileRules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 15000000 ||
            "maximal 15 MB"
          );
        },
        (value) => value.length > 0 || "Ein Dokument ist erforderlich",
      ],
      tagRules: [
        (value) =>
          (value !== null && value !== undefined && value.length > 0) ||
          "Ein Tag ist erforderlich",
      ],
    };
  },
  methods: {
    async submitForm() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.uploadFile();
        this.$refs.form.reset();
      }
    },
    async checkOwner() {
      try {
        const response = await isOwnerOfProperty(this.propertyId);
        this.currentUserIsOwner = response;
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Laden der Daten. ",
          error
        );
      }
    },
    async uploadFile() {
      const url = `property/${this.propertyId}/files/upload/sorted`;
      let formData = new FormData();
      for (let i = 0; i < this.file.length; i++) {
        formData.append("files", this.file[i]);
      }
      formData.append("tags", this.selectedTag);
      this.isLoading = true;
      try {
        const response = await ApiService.postWithAdditionalHeader(
          url,
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );
        this.files.push(...response.data);
      } catch (error) {
        console.error(error);
        if (error && error.status === 413) {
          this.notificationStore.pushError("Die Datei ist zu groß.");
        } else {
          this.notificationStore.pushError(
            "Es ist ein Fehler aufgetreten.",
            error
          );
        }
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTags() {
      this.tags = await fetchTags();
    },
    async fetchFiles() {
      this.files = await fetchFiles(this.propertyId);
      console.log("Files", this.files);
    },
  },
  async created() {
    await this.fetchTags();
    await this.fetchFiles();
    await this.checkOwner();
  },
};
</script>
