<template>
  <v-dialog v-model="show" max-width="500px">
    <template v-slot:activator="{ props: activatorProps }">
      <slot name="activator" :activator="activatorProps"> </slot>
    </template>
    <v-sheet>
      <v-tabs grow v-model="tab">
        <v-tab value="gebäude" color="accent" text="Gebäude anlegen"></v-tab
        ><v-tab text="Grundstück anlegen" color="accent" value="land"></v-tab
      ></v-tabs>
      <v-window v-model="tab">
        <v-window-item value="gebäude">
          <CreateBuilding
            @success="success"
            @close="close"
            :property="property"
            :click-event="clickEvent"
          />
        </v-window-item>
        <v-window-item class="pa-5" value="land"
          ><CreateLand
            :property="property"
            :click-event="clickEvent"
            @success="success"
            @close="close"
        /></v-window-item>
      </v-window>
    </v-sheet>
  </v-dialog>
</template>

<script>
import CreateBuilding from "@/components/property/CreateBuilding.vue";
import CreateLand from "@/components/property/create/CreateLand.vue";

export default {
  emits: ["success"],
  name: "CreateBuidlingPopup",
  components: { CreateBuilding, CreateLand },
  props: {
    property: Object,
    clickEvent: Object,
  },
  data() {
    return {
      show: false,
      tab: 0,
    };
  },
  methods: {
    success() {
      this.$emit("success");
      this.close();
    },
    close() {
      console.log("close");
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
