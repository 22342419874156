<template>
  <section class="mt-8" :class="customClass">
    <h3 class="text-center text-md-left">Alle Dokumente zu diesem Objekt</h3>
    <v-divider
      class="mb-5 mt-1 border-opacity-100"
      color="accent"
      thickness="1"
    ></v-divider>
    <v-autocomplete
      v-if="files"
      append-icon="mdi-filter-outline"
      :items="allTags"
      label="Dokumente nach Tags filtern"
      v-model="filterTags"
      item-title="name"
      item-value="key"
      density="comfortable"
      multiple
      chips
      clearable
    ></v-autocomplete>
    <v-card>
      <v-list
        class="text-left d-flex flex-column flex-wrap ga-2"
        rounded
        v-if="files && files.length > 0"
      >
        <v-card-text style="max-height: 500px">
          <div v-for="(file, index) of filteredFiles" :key="file.id">
            <v-list-item class="mt-2" rounded :title="file.filename">
              <template v-slot:prepend>
                <v-avatar color="grey-darken-1">
                  <v-icon :icon="getIcon(file.type)" color="white"></v-icon>
                </v-avatar>
              </template>
              <template v-slot:append>
                <v-dialog width="auto" scrollable v-if="hasRole">
                  <template v-slot:activator="{ props: activatorProps }">
                    <v-btn
                      icon="mdi-tag-edit-outline"
                      color="grey-darken-3"
                      size="small"
                      class="mr-2"
                      variant="flat"
                      v-tooltip:top="{
                        text: 'Tags bearbeiten',
                        openDelay: 1000,
                      }"
                      v-bind="activatorProps"
                      @click="openDialog(file)"
                    ></v-btn>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card
                      prepend-icon="mdi-tag-outline"
                      :title="`Tags für ${file.filename}`"
                    >
                      <v-divider class="mt-3"></v-divider>

                      <v-card-text style="height: 300px">
                        <v-checkbox
                          v-for="tag of allTags"
                          :key="tag.key"
                          v-model="newTags"
                          :label="tag.name"
                          :value="tag.key"
                        ></v-checkbox>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          text="Abbrechen"
                          @click="isActive.value = false"
                        ></v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                          color="accent"
                          text="Speichern"
                          variant="flat"
                          @click="
                            saveTags(file.id);
                            isActive.value = false;
                          "
                        ></v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <v-btn
                  icon="mdi-file-download-outline"
                  color="grey-darken-3"
                  size="small"
                  variant="flat"
                  v-tooltip:top="{
                    text: 'Datei herunterladen',
                    openDelay: 1000,
                  }"
                  @click="downloadFile(file.id, file.filename)"
                ></v-btn>
              </template>

              <v-chip
                v-for="tag of file.tags"
                :key="tag.key"
                size="x-small"
                color="grey"
                class="mr-1 mb-1"
              >
                {{ tag.name }}
              </v-chip>
            </v-list-item>
            <v-divider
              class="mx-6 mt-2"
              v-if="index < filteredFiles.length - 1"
            ></v-divider>
          </div>
        </v-card-text>
      </v-list>

      <div class="my-16 text-center" v-else>Noch keine Dokumente vorhanden</div>
    </v-card>
  </section>
</template>

<script>
import ApiService from "@/services/api.service";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import { useNotificationStore } from "@/stores/modules/notification.module";

export default {
  setup() {
    const authStore = useAuthUserStore();
    const hasRole = authStore.hasRole(["ROLE_ADMIN", "ROLE_AGENT"]);
    const notificationStore = useNotificationStore();

    return {
      hasRole,
      notificationStore,
    };
  },
  props: {
    files: Array,
    allTags: Array,

    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bytes: [],
      newTags: [],
      filterTags: [],
    };
  },
  computed: {
    filteredFiles() {
      if (!this.filterTags.length) {
        return this.files;
      }
      return this.files.filter((file) =>
        file.tags.some((tag) => this.filterTags.includes(tag.key))
      );
    },
  },
  methods: {
    getIcon(fileType) {
      //   console.log("fileType:", fileType);
      switch (fileType) {
        case "application/pdf":
          return "mdi-file-pdf-box";
        case "image/png":
          return "mdi-file-image";
        case "image/jpg":
          return "mdi-file-image";
        case "image/jpeg":
          return "mdi-file-image";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "mdi-file-document";
        default:
          return "mdi-file";
      }
    },
    async downloadFile(id, name) {
      try {
        const response = await ApiService.post(
          "file/get/bytes",
          { text: id },
          { responseType: "blob" }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name); // set the file name and extension here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Herunterladen der Datei.",
          error
        );
      }
    },
    async saveTags(id) {
      try {
        const response = await ApiService.post("file/tags/set", {
          id: id,
          tags: this.newTags,
        });
        if (response.status === 200) {
          this.updateFileTags(id, this.newTags);
        }
      } catch (error) {
        this.notificationStore.pushError(
          "Fehler beim Speichern der Tags.",
          error
        );
      }
    },
    updateFileTags(id, newTags) {
      const file = this.files.find((file) => file.id === id);
      if (file) {
        file.tags = newTags.map((tagKey) =>
          this.allTags.find((tag) => tag.key === tagKey)
        );
      }
    },
    openDialog(file) {
      this.newTags = file.tags.map((tag) => tag.key);
    },
  },
};
</script>
