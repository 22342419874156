<template>
  <EditOfferDialog :disabled="offerC.status==='ACCEPTED'" :property-id="propertyId" v-model:offer="offerC"></EditOfferDialog>
  <v-btn :disabled="offerC.status==='ACCEPTED'" @click="withdrawOffer" color="red" variant="text">Angebot zurückziehen</v-btn>
</template>
<script>
import EditOfferDialog from "@/components/property/offer/EditOfferDialog.vue";
import {withdrawOffer} from "@/services/saleProcess/offer.service";

export default {
  name: 'OfferActionButtons',
  components: {EditOfferDialog},
  props: {
    offer:Object,
    propertyId:String,
  },
  methods:{
    async withdrawOffer() {
      if(!confirm("Möchten Sie das Angebot wirklich zurückziehen?")) return;
      const data = await withdrawOffer(this.offer.realEstateId);
      if(data === "WITHDRAWED") this.offerc.status="WITHDRAWED"
    }
  },
  computed: {
    offerC: {
      get() {
        return this.offer;
      },
      set(value) {
        this.$emit('update:offer', value);
      }
    }
  }
}
</script>
