<template>
  <label class="text-left d-flex flex-wrap" :class="inputClass">
    <p v-if="label" style="width: 100%">{{ label }}</p>

    <input
      class="custom-input"
      :class="inputClass"
      style="width: 100%"
      :type="type"
      :value="inputValue"
      :placeholder="placeholder"
      :required="required"
      @input="$emit('update:inputValue', $event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: "KreaInput",
  props: {
    rules: Array,
    type: String,
    placeholder: String,
    inputValue: String,
    label: String,
    inputClass: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:inputValue"],
};
</script>

<style scoped>
.custom-input {
  padding: 13px;
  border: 1px solid #9b9898b8;
  border-radius: 4px;
}
</style>
