<template>
  <section class="mt-8">
    <v-row
      ><v-col class="pb-0 pb-sm-3" cols="12" sm="6"
        ><v-autocomplete
          prepend-icon="mdi-account-search"
          :items="allNames"
          label="Nach Namen suchen"
          density="comfortable"
          v-model="searchName"
          clearable
        ></v-autocomplete></v-col
      ><v-col cols="12" sm="6" class="pt-0 pt-sm-3"
        ><v-select
          prepend-icon="mdi-filter-outline"
          :items="allStatuses"
          label="Nach Status filtern"
          v-model="selectedStatus"
          density="comfortable"
          item-title="text"
          item-value="value"
          multiple
          chips
          clearable
          @keydown.enter.prevent
        ></v-select></v-col
    ></v-row>

    <v-card class="elevation-0">
      <v-list
        class="text-left d-flex flex-column flex-wrap ga-2"
        rounded
        v-if="filteredUsers.length != 0"
      >
        <v-card-text style="max-height: 700px" class="ma-0 pa-0">
          <template v-for="(user, index) of filteredUsers" :key="user.id">
            <v-list-item rounded class="ma-0 px-3 py-0">
              <v-row
                class="ma-0 pa-0 text-center text-sm-left text-md-center text-lg-left"
              >
                <v-col cols="12" sm="4" md="12" lg="4">
                  <h3 class="mb-2">
                    {{ user.name }}
                    <v-chip v-if="user.state" :color="chipColor(user.state)"
                      ><EnumDisplay
                        :enumObject="LongListState"
                        :value="user.state"
                    /></v-chip>
                  </h3>
                </v-col>
                <v-col cols="12" sm="4" md="12" lg="4">
                  <p v-if="user.email" class="mb-2">
                    <v-icon color="grey-lighten-1" class="mr-1"
                      >mdi-email-outline</v-icon
                    >
                    <a :href="'mailto:' + user.email" class="text-accent">{{
                      user.email
                    }}</a>
                  </p>
                  <p v-if="user.tel">
                    <v-icon color="grey-lighten-1" class="mr-1"
                      >mdi-phone-outline</v-icon
                    >
                    <a :href="'tel:' + user.tel" class="text-accent">
                      {{ user.tel }}</a
                    >
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="12"
                  lg="4"
                  class="text-center text-sm-right text-md-center text-lg-right"
                >
                  <v-dialog max-width="500">
                    <template v-slot:activator="{ props: activatorProps }">
                      <v-btn
                        v-bind="activatorProps"
                        icon="mdi-check"
                        size="small"
                        class="ma-1"
                        variant="flat"
                        v-tooltip:top="{
                          text: 'als Interessent markieren',
                          openDelay: 1000,
                        }"
                        :disabled="user.state === 'INTERESTED'"
                        :color="
                          user.state === 'INTERESTED'
                            ? 'accent'
                            : 'grey-darken-3'
                        "
                      ></v-btn>
                    </template>
                    <template v-slot:default="{ isActive }"
                      ><v-card title="Interessenten anlegen">
                        <v-card-text>
                          <v-slider
                            v-model="buyIntention"
                            :max="10"
                            :min="1"
                            step="1"
                            thumb-label="always"
                            thumb-size="24"
                            label="Kaufabsicht"
                            class="mt-5"
                          />
                          <v-textarea
                            v-model="user.note"
                            label="Notiz"
                            outlined
                            rows="3"
                          />
                        </v-card-text>

                        <v-card-actions>
                          <v-btn
                            text="Abbrechen"
                            @click="isActive.value = false"
                          ></v-btn>
                          <v-spacer></v-spacer>

                          <v-btn
                            text="Interessent anlegen"
                            color="accent"
                            @click="
                              handleStateChange(
                                user.id,
                                'INTERESTED',
                                buyIntention,
                                user.note
                              ),
                                (isActive.value = false)
                            "
                          ></v-btn>
                        </v-card-actions> </v-card
                    ></template>
                  </v-dialog>
                  <v-btn
                    icon="mdi-close"
                    size="small"
                    class="ma-1"
                    variant="flat"
                    v-tooltip:top="{
                      text: 'als Irrelevant markieren',
                      openDelay: 1000,
                    }"
                    @click="handleStateChange(user.id, 'IRRELEVANT', 0, '')"
                    :disabled="user.state === 'IRRELEVANT'"
                    :color="
                      user.state === 'IRRELEVANT' ? 'accent' : 'grey-darken-3'
                    "
                  ></v-btn>

                  <v-menu location="bottom" transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        icon="mdi-dots-horizontal"
                        color="grey-darken-3"
                        size="small"
                        class="ma-1"
                        variant="flat"
                        v-tooltip:top="{
                          text: 'weitere Optionen',
                          openDelay: 1000,
                        }"
                      ></v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        ><v-btn
                          text
                          class="justify-start"
                          block
                          @click="handleStateChange(user.id, 'PENDING', 0, '')"
                          :disabled="user.state === 'PENDING'"
                          :color="user.state === 'PENDING' ? 'accent' : ''"
                          >Offen</v-btn
                        >
                      </v-list-item>

                      <v-list-item
                        ><v-btn
                          @click="
                            handleStateChange(
                              user.id,
                              'WAITING_FOR_RESPONSE',
                              0,
                              ''
                            )
                          "
                          :disabled="user.state === 'WAITING_FOR_RESPONSE'"
                          text
                          class="justify-start"
                          block
                          :color="
                            user.state === 'WAITING_FOR_RESPONSE'
                              ? 'accent'
                              : ''
                          "
                        >
                          Rückmeldung ausstehend
                        </v-btn></v-list-item
                      ><v-list-item
                        ><v-btn
                          @click="
                            handleStateChange(user.id, 'NO_RESPONSE', 0, '')
                          "
                          :disabled="user.state === 'NO_RESPONSE'"
                          text
                          class="justify-start"
                          block
                          :color="user.state === 'NO_RESPONSE' ? 'accent' : ''"
                        >
                          Keine Rückmeldung
                        </v-btn></v-list-item
                      >
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="py-0"
                  ><v-textarea
                    class="text-grey font-italic"
                    placeholder="Notiz hinzufügen..."
                    prepend-inner-icon="mdi-note-outline"
                    :variant="showSaveNoteBtn[index] ? 'solo' : 'plain'"
                    rows="1"
                    auto-grow
                    max-rows="3"
                    text="hallo test"
                    density="compact"
                    counter
                    @click="showSaveNoteBtn[index] = true"
                    @blur="hideSaveButton(index)"
                    v-model="user.note"
                    ><template v-if="showSaveNoteBtn[index]" #append-inner>
                      <v-divider inset vertical></v-divider>
                      <v-btn
                        class="ma-0 ml-3"
                        icon="mdi-check"
                        color="white"
                        size="small"
                        variant="plain"
                        rounded
                        tile
                        @click="
                          handleStateChange(user.id, user.state, 0, user.note)
                        "
                      ></v-btn>
                      <v-btn
                        class="ma-0"
                        icon="mdi-trash-can-outline"
                        color="white"
                        size="small"
                        variant="plain"
                        rounded
                        tile
                        @click="
                          (user.note = ''), (showSaveNoteBtn[index] = false)
                        "
                      ></v-btn></template></v-textarea
                ></v-col>
              </v-row>
            </v-list-item>

            <v-divider
              class="mx-3 my-2"
              v-if="index < filteredUsers.length - 1"
            ></v-divider>
          </template>
        </v-card-text>
      </v-list>

      <div class="my-8 text-center" v-else>Keine Einträge vorhanden</div>
    </v-card>
  </section>
</template>

<script>
import { useNotificationStore } from "@/stores/modules/notification.module";
import ApiService from "@/services/api.service";
import EnumDisplay from "@/components/helpers/EnumDisplay.vue";
import ELongListState from "@/components/enums/ELongListState";

export default {
  name: "LongList",
  props: {
    propertyId: String,
  },
  components: {
    EnumDisplay,
  },
  emits: ["interest-added"],
  setup() {
    const notificationStore = useNotificationStore();
    return {
      notificationStore,
    };
  },
  data() {
    return {
      showSaveNoteBtn: [], // Ein Array, um den Zustand jedes Speichern-Knopfs zu speichern
      buyIntention: 0,
      notes: [],
      interestData: {},
      selectedStatus: ["PENDING", "WAITING_FOR_RESPONSE"],
      searchName: null,
      users: [],
      LongListState: ELongListState,
    };
  },
  methods: {
    hideSaveButton(index) {
      setTimeout(() => {
        this.showSaveNoteBtn[index] = false;
      }, 200);
    },
    async handleStateChange(entryId, state, buyIntention, note) {
      const interestData = {
        propertyId: Number(this.propertyId),
        state: state,
        buyIntention: buyIntention,
        note: note,
      };
      try {
        const response = await ApiService.post(
          `long-list/entry/${entryId}/status/change`,
          interestData
        );
        this.users = this.users.map((user) =>
          user.id === entryId ? response.data : user
        );
        if (state === "INTERESTED") {
          this.users = this.users.filter((user) => user.id !== entryId);
          this.$emit("interest-added");
        }
      } catch (error) {
        this.notificationStore.pushError("Fehler beim Laden der Daten.", error);
      }
    },
    removeUserFromList(user) {
      const index = this.users.indexOf(user);
      if (index !== -1) {
        this.users.splice(index, 1);
      }
    },
    async getLongList() {
      try {
        const response = await ApiService.get(
          `long-list/${this.propertyId}/get`
        );
        this.users = response.data.entries;
      } catch (error) {
        this.notificationStore.pushError("Fehler beim Laden der Daten.", error);
      }
    },
    chipColor(state) {
      switch (state) {
        case "PENDING":
          return "blue";
        case "NO_RESPONSE":
          return "orange";
        case "WAITING_FOR_RESPONSE":
          return "yellow";
        case "INTERESTED":
          return "green";
        case "IRRELEVANT":
          return "red";
        default:
          return "grey";
      }
    },
  },
  async created() {
    await this.getLongList();
  },
  computed: {
    allNames() {
      return this.users
        .map((user) => user.name)
        .filter((name, index, self) => self.indexOf(name) === index);
    },
    filteredUsers() {
      let filtered = this.users;
      if (this.searchName) {
        filtered = filtered.filter((user) =>
          user.name.includes(this.searchName)
        );
      }
      if (this.selectedStatus && this.selectedStatus.length > 0) {
        filtered = filtered.filter(
          (user) => this.selectedStatus.includes(user.state) // Benutzen Sie 'state' anstatt 'status'
        );
      }
      return filtered;
    },
    allStatuses() {
      return Object.entries(this.LongListState).map(([key, value]) => ({
        text: value.de,
        value: key,
      }));
    },
  },
};
</script>

<style scoped>
a.text-accent:hover {
  opacity: 0.7;
}
</style>
